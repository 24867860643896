import { RootState } from '@/store/store'
import { createSelector } from '@reduxjs/toolkit'
import { ChatV2Conversation, ChatV2Feature, ChatV2MessageType } from './chat-v2.slice'
import { ModeChoice } from '../schemas/chat-query-metadata-schema'
import { selectFileProcessingStatusesWithKeys } from '@/store/selectors/file-upload-tasks-reducers.selector'
import { FileProcessingStatus } from '@/store/slices/file-upload-tasks.slice'

/**
 * SELECTOR TEMPLATE
 * A selector is a function that takes the Redux state as an argument and returns a value.
 * createSelector is a function that creates a memoized selector to prevent unnecessary re-renders.
 *
 * This selector retrieves a specific message from a conversation based on 'chatId' and 'messageId'.
 */
// const exampleSelector = createSelector(
//   // Input selector 1 - dive into the relevant part of state to watch (specific conversation)
//   (state: RootState, props: { chatId: string }) => state.chatV2State.conversations[props.chatId],

//   // Input selector 2 - additional props needed for computation (supplied by watching component)
//   (_: RootState, props: { messageId: string }) => props,

//   // Output selector/result function - return the result of the computation
//   (conversation, props) => {
//     const { messageId } = props

//     // Do some computation
//     return conversation?.messages[messageId] ?? null
//   },

//   // Optional options object
//   {
//     memoizeOptions: {
//       // Optional Custom equality check
//       // Only recompute the selector if the previous and next values are different
//       // Can be used to compare specific parts of the state object and not
//       // recompute if other parts change
//       equalityCheck: (prevConversation, nextConversation) => {
//         // Example, only recomputes if the input text is different
//         return prevConversation.currentTextInput === nextConversation.currentTextInput
//       },
//     },
//   }
// )

/**
 * CHILD SELECTOR TEMPLATE
 * A child selector is a selector that depends on another selector.
 * This only recomputes when the parent selector changes, not the entire state.
 *
 * This selector checks if a given search string is present in a specific message's text.
 */
// const exampleChildSelector = createSelector(
//   // Input selector 1 - parent selector
//   exampleSelector,

//   // Input selector 2 - additional props needed for computation (supplied by watching component)
//   (_: RootState, props: { search: string }) => props,

//   // Output selector/result function - return the result of the computation
//   (exampleSelectorResult, props) => {
//     return exampleSelectorResult?.text.includes(props.search) ?? false
//   }
// )

/**
 * Get Conversation
 * An input function that retrieves a conversation with a given 'chatId' from the 'conversations' object.
 * Returns null if the conversation does not exist.
 * Selectors can use a function like this as the first input for convenience.
 */
export const getConversationFromId = (state: RootState, props: { chatId: string | undefined }): ChatV2Conversation | null => {
  return state.chatV2State.conversations[props.chatId ?? ''] ?? null
}

/**
 * Selector: Single Conversation By Id
 */
export const selectConversationFromId = createSelector(
  // Input 1: The getConversationFromId function
  getConversationFromId,

  // Input 2: No additional props needed for this computation
  // Do not need to define here

  // Output selector/result function:
  // Returns the conversation if it exists, null otherwise.
  (conversation) => conversation
)

/**
 * Selector: Conversation Exists
 * A child selector that checks if a conversation with a given 'chatId' exists based on
 * the selectConversationFromId parent selector.
 *
 * Reduces re-renders: The Input 1 selector is the output of getConversationFromId rather than the root state.
 * This means that the selector will only recompute when the conversation with the given 'chatId' changes,
 * and not the rest of the state.
 */
export const selectConversationExists = createSelector(
  // Input 1: The output of the 'selectorInputConversationFromId' selector.
  getConversationFromId,

  // Input 2: No additional props needed for this computation
  // Do not need to define here

  // Output selector/result function:
  // Returns 'true' if a conversation exists, 'false' otherwise.
  // The double negation (!!) ensures a boolean result.
  (converstaion) => !!converstaion
)

/**
 * Selector: Visible Reference Exists
 * A child selector that checks if a visible reference exists in a conversation.
 */
export const selectConversationVisibleReferenceExists = createSelector(getConversationFromId, (conversation) => !!conversation?.visibleReference)

/**
 * Selector: Visible Reference
 * A selector that retrieves the visible reference from a conversation.
 */
export const selectVisibleReference = createSelector(
  (state: RootState, props: { chatId: string }) => state.chatV2State.conversations[props.chatId]?.visibleReference ?? null,
  (visibleReference) => visibleReference
)

/**
 * Selector: Visible Reference Needs PDF Renderer
 * A selector that retrieves whether the visible reference needs the PDF renderer
 */
export const selectVisibleReferenceUsesPDFRenderer = createSelector(
  (state: RootState, props: { chatId: string | undefined }) => state.chatV2State.conversations[props.chatId ?? '']?.visibleReference ?? null,
  (visibleReference) => visibleReference?.metadata?.process_version === 'V2'
)

/**
 * Selector Visible Reference Render Trigger
 * A selector that retrieves the render trigger for the visible reference from a conversation.
 */
export const selectVisibleReferenceRenderTrigger = createSelector(
  (state: RootState, props: { chatId: string }) => state.chatV2State.conversations[props.chatId]?.visibleReferenceRenderTrigger ?? '',
  (renderTrigger) => renderTrigger
)

/**
 * Selector: Conversation Is Loading
 */
export const selectConversationIsLoading = createSelector(
  // Input selector: Extract 'isLoading' directly from the state
  (state: RootState, props: { chatId: string | undefined }) => state.chatV2State.conversations[props.chatId ?? '']?.isLoading ?? false,
  // Result function: Simply return 'isLoading'
  (isLoading) => isLoading
)
/**
 * Selector: Conversation Feature
 */
export const selectConversationFeature = createSelector(
  // Input selector: Extract 'feature' directly from the state
  (state: RootState, props: { chatId: string | undefined }) => state.chatV2State.conversations[props.chatId ?? '']?.feature ?? null,
  // Result function: Return 'feature'
  (feature) => feature
)

/**
 * Select: isDiscontinuedFeature
 */
export const selectIsDiscontinuedFeature = createSelector(
  // Input selector: Extract 'feature' directly from the state
  (state: RootState, props: { chatId: string | undefined }) => state.chatV2State.conversations[props.chatId ?? '']?.feature ?? null,
  // Result function: Check if the feature is discontinued
  (feature) => {
    return feature === ChatV2Feature.lrr
  }
)

/**
 * Selector: Conversation Messages
 */
export const selectConversationMessages = createSelector(
  // Input selector: Extract 'messages' directly from the state
  (state: RootState, props: { chatId: string | undefined }) => state.chatV2State.conversations[props.chatId ?? '']?.messages ?? {},
  // Result function: Return 'messages'
  (messages) => messages
)

/**
 * Selector: Conversation current source
 */
export const selectConversationCurrentSource = createSelector(
  // Input selector: Extract 'currentSource' directly from the state
  (state: RootState, props: { chatId: string | undefined }) => state.chatV2State.conversations[props.chatId ?? '']?.currentSource ?? null,
  // Result function: Return 'currentSource'
  (currentSource) => currentSource
)

/**
 * Selector: Conversation Title
 */
export const selectConversationTitle = createSelector(
  // Input selector: Extract 'title' directly from the state
  (state: RootState, props: { chatId: string | undefined }) => state.chatV2State.conversations[props.chatId ?? '']?.title ?? null,
  // Result function: Return 'title'
  (title) => title
)

/**
 * Selector: Conversation current selected files
 * selected files is used on LRR and Assistant current source.
 * It is used to store the files that the user has selected to be used in the current source.
 * Array of file paths.
 */
export const selectSelectedFiles = createSelector(
  (state: RootState, props: { chatId: string | undefined }) => state.chatV2State.conversations[props.chatId ?? '']?.currentSource?.selected_files ?? [],
  (selectedFiles) => selectedFiles as string[]
)

/**
 * Selector for conversation message ids
 * - Reduces re-renders since it's inputs won't change as frequently as if we were watching the root state object
 *
 * - Custom input equality check to compare the previous and next message keys
 * - Inputs will not be considered different if the keys are the same, even if the message objects are different
 * - Default behavior would recompute the selector (and re-render) every time the 'messages' objects change
 */

/**
 * Message Keys Equality Check
 * Custom equality check to compare the previous and next message keys
 * and ignore other changes in the objects
 * @param prevMessages
 * @param nextMessages
 * @returns
 */
const equalityCheckMessageKeys = (prevMessages: Record<string, any>, nextMessages: Record<string, any>) => {
  const prevKeys = prevMessages ? Object.keys(prevMessages) : []
  const nextKeys = nextMessages ? Object.keys(nextMessages) : []
  if (prevKeys.length !== nextKeys.length) return false

  // Return true if every key at each index is the same
  return prevKeys.every((key, index) => key === nextKeys[index])
}
export const selectConversationMessageIds = createSelector(
  // INPUT 1
  selectConversationMessages,

  (messages) => Object.keys(messages),
  {
    memoizeOptions: {
      // Custom equality check evaluates INPUT 1 to determine whether it has changed
      equalityCheck: equalityCheckMessageKeys,
    },
  }
)

/**
 * Selector to get a message by id
 */
export const selectMessageFromId = createSelector(
  (state: RootState, props: { chatId: string; messageId: string }) => {
    const conversation = state.chatV2State.conversations[props.chatId]
    return conversation?.messages[props.messageId] ?? null
  },
  (message) => message
)

/**
 * Child selector: message type
 */
export const selectMessageType = createSelector(
  (state: RootState, props: { chatId: string; messageId: string }) => {
    const conversation = state.chatV2State.conversations[props.chatId]
    return conversation?.messages[props.messageId]?.metadata?.message_type ?? null
  },
  (messageType) => messageType
)

/**
 * Selector to see if the messageId is the most recent message
 */
export const selectIsMostRecentMessage = createSelector(
  selectConversationMessages,
  (_: RootState, props: { messageId: string }) => props,
  (messages, props) => {
    // Get most recent message
    const mostRecentMessage = Object.values(messages).reverse()[0] ?? null

    // The provided messageId matches the most recent message
    return mostRecentMessage?.metadata?.message_id === props.messageId
  }
)

/**
 * Selector for whether submission in this conversation is blocked
 * Returns true if the conversation is loading, the form has an error, the current source is null, the selected files are empty or any of the selected files are processing.
 *
 * Does not check for an empty text input.
 */
export const selectConversationSubmitBlocked = createSelector(
  // Input 1: Get the conversation based on chatId
  (state: RootState, props: { chatId: string | undefined }) => state.chatV2State.conversations[props.chatId ?? ''] ?? null,

  // Input 2: Get processing statuses of the selected files if is feature is assistant - todo expand this to other features in the future.
  (state: RootState, props: { chatId: string | undefined }) => {
    const conversation = state.chatV2State.conversations[props.chatId ?? ''] ?? null
    if (!conversation) return {}

    const feature = conversation.feature as ChatV2Feature
    if (feature !== ChatV2Feature.assistant) return {}

    const currentSource = conversation.currentSource ?? null
    if (!currentSource) return {}

    // Extract selected file keys for the assistant feature
    const selectedFileKeys = currentSource.selected_files ?? []
    if (selectedFileKeys.length === 0) return {}

    // Use selectFileProcessingStatusesWithKeys to get the processing statuses
    return selectFileProcessingStatusesWithKeys(state, { keys: selectedFileKeys })
  },

  (conversation, processingStatuses) => {
    if (!conversation) return true

    // Infer values
    const feature = conversation.feature as ChatV2Feature
    const isLoading = conversation.isLoading
    const messages = conversation.messages ?? {}
    const currentSource = conversation.currentSource ?? null
    const formValidationError = conversation.formValidationError ?? false

    // Global settings for all features
    if (isLoading || formValidationError || !currentSource) return true

    // Feature-specific conditions
    switch (feature) {
      // Features that require selected files
      case ChatV2Feature.documentquery:
        // If there are no selected files
        if (currentSource.selected_files.length === 0) return true
        break

      case ChatV2Feature.contractanalysis:
        // If the file path is empty or there are already messages (only 1 message allowed)
        if (currentSource.file_path === '' || Object.keys(messages).length > 0) return true
        break

      case ChatV2Feature.documentSummarization:
        // If selected file is empty
        if (currentSource.selected_file === '') return true
        break

      case ChatV2Feature.lrr_v2: {
        // If file path is empty
        const lrrSelections = currentSource.lrr_selections ?? []
        if (lrrSelections.length === 0) return true
        break
      }

      case ChatV2Feature.research:
        // If neither federal or state sources are selected
        if (currentSource.federal_court_ids == null && currentSource.state_court_ids == null) return true
        break

      case ChatV2Feature.assistant: {
        // If any files in the conversation are pending, processing or has errors
        const hasUnprocessedFiles = Object.values(processingStatuses).some((status) =>
          [FileProcessingStatus.PENDING, FileProcessingStatus.PROCESSING, FileProcessingStatus.ERROR].includes(status as FileProcessingStatus)
        )
        if (hasUnprocessedFiles) return true

        // If no files are selected in document query mode
        if (currentSource?.focus_mode === ModeChoice.DOCUMENTQUERY && currentSource?.selected_files.length === 0) return true
        break
      }
    }

    return false
  }
)

/**
 * Selector: The current text input in the conversation
 */
export const selectConversationCurrentTextInput = createSelector(
  (state: RootState, props: { chatId: string }) => state.chatV2State.conversations[props.chatId]?.currentTextInput ?? '',
  (currentTextInput) => currentTextInput
)

/**
 * Selector for whether to show the loading bubble
 * If
 * - The conversation is loading
 * - The most recent message is a user_query
 * - The text is empty
 */
export const selectShowMessageAsLoadingBubble = createSelector(
  getConversationFromId,
  (_: RootState, props: { messageId: string }) => props,
  (conversation, props) => {
    if (!conversation) return false
    const messages = conversation.messages
    if (!messages) return false

    // Get most recent message
    const mostRecentMessage = Object.values(messages).reverse()[0] ?? null

    // The provided messageId matches the most recent message
    const isMostRecentMessage = mostRecentMessage?.metadata?.message_id === props.messageId

    // Is the most recent message a user_query?
    const mostRecentMessageIsUserQuery = mostRecentMessage?.metadata?.message_type === ChatV2MessageType.user_query

    return conversation.isLoading && isMostRecentMessage && mostRecentMessageIsUserQuery
  }
)

/**
 * COMBINED Selector to get the processing statuses for the selected files.
 *
 * This selector takes the selected files and their corresponding processing statuses,
 * and maps the processing statuses to the selected files. If a selected file does not
 * have a processing status, it defaults to `FileProcessingStatus.SUCCESS`.
 *
 * @param state - The root state of the application.
 * @param props - The properties containing the chat ID or keys.
 * @returns A record where the keys are the selected file keys and the values are their processing statuses.
 */
export const selectProcessingStatusesForSelectedFiles = createSelector(
  // Input selectors
  (state: RootState, props: { chatId: string | undefined }) => selectSelectedFiles(state, props),
  (state: RootState, props: { keys: string[] }) => selectFileProcessingStatusesWithKeys(state, props),
  // Result function
  (selectedFiles, processingStatuses) => {
    // Map processing statuses to selected files
    return selectedFiles.reduce((acc: Record<string, string>, key: string) => {
      if (processingStatuses[key]) {
        acc[key] = processingStatuses[key]
      } else {
        acc[key] = FileProcessingStatus.SUCCESS
      }
      return acc
    }, {})
  }
)

/**
 * Selector: New Conversation ID UX Trigger
 * A selector that updates when the new conversation ID UX trigger changes.
 */
export const selectNewConversationIdUxTrigger = createSelector(
  // Input selector: Extract 'newConversationIdUxTrigger' from the state
  (state: RootState) => state.chatV2State.newConversationIdUXTrigger,

  // Result function: Return the new conversation ID UX trigger
  (trigger) => trigger
)

/**
 * Selector: Select Pending Conversations
 * Uses a custom equalityCheck to only recompute if the pending conversation ids change
 */
export const selectPendingConversations = createSelector(
  // Input selector: all conversations in the state
  (state: RootState) => state.chatV2State.conversations,

  // Result function: Filter for and return the pending conversations
  (conversations) => {
    return Object.values(conversations).filter((conversation) => conversation.isPending)
  },
  {
    memoizeOptions: {
      // Custom equality check to compare the ids for changes
      equalityCheck: (prevConversations: Record<string, ChatV2Conversation>, nextConversations: Record<string, ChatV2Conversation>) => {
        // Get the conversation ids
        const prevConversationIds = Object.keys(prevConversations)
        const nextConversationIds = Object.keys(nextConversations)

        // Fast check - If the lengths are different, there is a change
        if (prevConversationIds.length !== nextConversationIds.length) return false

        // Slower - another filter: prev and next pending conversation ids
        const prevPendingConversationIds = prevConversationIds.filter((key) => prevConversations[key].isPending)
        const nextPendingConversationIds = nextConversationIds.filter((key) => nextConversations[key].isPending)

        // Fast compare pending conversation ids length
        if (prevPendingConversationIds.length !== nextPendingConversationIds.length) return false

        // Last check - compare the pending conversation ids
        // Loop through the pending conversation ids and compare them
        // Exit as soon as a difference is found
        for (let i = 0; i < prevPendingConversationIds.length; i++) {
          if (prevPendingConversationIds[i] !== nextPendingConversationIds[i]) return false
        }

        // If we reached here, the pending conversation ids are the same
        return true
      },
    },
  }
)
