import { Fragment, useContext, useEffect, useState } from 'react'
import { Combobox, Dialog, Transition } from '@headlessui/react'
import { FullMetadata, StorageReference, getMetadata, getStorage, listAll, ref } from 'firebase/storage'
import { kGcsOrgHiddenFolder, kGcsUserFileUploadsBucketRef, kHiddenFileForFolderName } from '@/constants/constants-gcs'
import { getAuth } from 'firebase/auth'
import { Link } from 'react-router-dom'
import { Article, AttachFile, Folder, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { CircularProgressContinuous } from '@/components/loaders/CircularProgressContinuous'
import CircularProgress from '@mui/material/CircularProgress'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { chatV2CurrentSourceDocumentQueryClearAll, chatV2CurrentSourceDocumentQueryToggleSelectedFile } from '@/chat-common/store/chat-v2.slice'
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { AuthContext } from '@/context/auth-context'
import { BrandEnum } from '@/firebase/auth/auth-jwt-schema'
import { fileUploadIsProcessing } from '@/routes/dashboard/files/files-utils'
import { RootState } from '@/store/store'
import { selectConversationCurrentSource } from '@/chat-common/store/chat-v2.selectors'
import { ChatV2QueryMetadataDocumentQuery } from '@/chat-common/schemas/chat-query-metadata-schema'

enum ActiveDriveTab {
  MYDRIVE = 'MYDRIVE',
  SHAREDDRIVE = 'SHAREDDRIVE',
}

type DocumentSelectionDialogProps = {
  open: boolean
  onClose: (value: boolean) => void
  conversationId: string
}

/**
 * DocumentSelectionDialog component.
 *
 * This component is used exclusively in the Document Query.
 * It allows the user to select files from their File Drive.
 *
 * @param props - The properties for the DocumentSelectionDialog component.
 * @returns A JSX element representing the document selection dialog.
 */
export default function DocumentSelectionDialog(props: DocumentSelectionDialogProps) {
  const { open, onClose, conversationId } = props
  const dispatch = useAppDispatch()

  // Store data
  const currentSource = useAppSelector((state: RootState) =>
    selectConversationCurrentSource(state, { chatId: conversationId })
  ) as ChatV2QueryMetadataDocumentQuery | null
  const selectedFiles = currentSource?.selected_files ?? []

  // Context data
  const { userAccountData } = useContext(AuthContext)

  // Drive Selection State (storage folder location)
  const [activeDriveTab, setActiveDriveTab] = useState<ActiveDriveTab>(ActiveDriveTab.MYDRIVE)
  const inactiveDriveStyles =
    'grow border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium'
  const activeDriveStyles = ' grow border-sky-500 text-sky-600 w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium'

  // Local UI State (for retrieved files, folders, expansion setting, etc.)
  const [files, setFiles] = useState<Map<StorageReference | null, FullMetadata[]>>(new Map())
  const [folders, setFolders] = useState<StorageReference[]>([])
  const [expandedFolders, setExpandedFolders] = useState<StorageReference[]>([])
  const [query, setQuery] = useState('')
  const [loading, setLoading] = useState(false)

  const isFilesEmpty = folders.length === 0 && files.get(null) != undefined && files.get(null)!.length == 0
  const isFilesNotEmpty = folders.length > 0 || (files.get(null) && files.get(null)!.length > 0)

  // Fetch files on load
  useEffect(() => {
    getProcessedFiles({ folder: null, selectOnLoad: false, driveTab: activeDriveTab })
  }, [])

  // Handle the change of the active drive tab
  const handleActiveDriveTabChange = (tab: ActiveDriveTab) => {
    setActiveDriveTab(tab)

    // Reset the state of the files and folders
    setFiles(new Map())
    setFolders([])

    // Fetch files for this drive
    getProcessedFiles({ folder: null, selectOnLoad: false, driveTab: tab })
  }

  // Get the user's auth id
  const auth = getAuth()
  const uid = auth.currentUser?.uid

  // Inferred state
  const organizationId = userAccountData?.legacyDriveCompatibleOrganizationId
  const brand = userAccountData?.brand

  /**
   * Get list of processed files
   * @param args folder is the folder we are fetching files for, if null, gets root files. selectOnLoad is whether or not to select (check) the files when they are loaded
   * @returns
   */
  async function getProcessedFiles(args: { folder: StorageReference | null; selectOnLoad: boolean; driveTab: ActiveDriveTab }) {
    const { folder, selectOnLoad, driveTab } = args
    setLoading(true)

    // Derive the drive root
    const driveRootPath = driveTab == ActiveDriveTab.MYDRIVE ? `users/${uid}` : `tenants/${organizationId}`

    const storage = getStorage(undefined, kGcsUserFileUploadsBucketRef)
    const listRef = ref(storage, folder?.fullPath ?? driveRootPath) // default to 'users/{uid}' (root) if folder is null

    try {
      // Get all the files
      const response = await listAll(listRef)

      // If folder is null (we're at root), set all the folders to state
      if (folder == null) {
        // Set folders to state
        setFolders(response.prefixes)
      }

      // Get the metadata for each file
      const metadataPromises: Promise<FullMetadata>[] = []
      response.items.forEach((itemRef) => {
        metadataPromises.push(getMetadata(itemRef))
      })

      // Wait for all the metadata to be retrieved
      const metadataResults = await Promise.all(metadataPromises)

      // Update state
      setFiles((prev) => {
        const newFiles = new Map(prev)
        newFiles.set(folder, metadataResults)
        return newFiles
      })

      // If select on load, select the files
      if (selectOnLoad) {
        for (const file of metadataResults) {
          dispatch(chatV2CurrentSourceDocumentQueryToggleSelectedFile({ conversationId: conversationId, filePath: file.fullPath }))
        }
      }
    } catch (e) {
      console.error('Error getting files: ', e)
    } finally {
      setLoading(false)
    }
  }

  if (!open) return null

  // Extract vars for rendering
  const keys: (StorageReference | null)[] = [...files.keys()]

  return (
    <Transition.Root show={open} as={Fragment} afterLeave={() => setQuery('')} appear>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto px-4 py-20 lg:ml-[300px]">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-xl transform rounded-xl bg-white p-2 shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              {/* Drive Choice Tabs - for users with an organization */}
              {organizationId && brand != BrandEnum.HAIKU && (
                <div className="border-b border-gray-200 mb-2">
                  <nav className="w-full flex items-center" aria-label="Tabs">
                    <button
                      onClick={() => {
                        handleActiveDriveTabChange(ActiveDriveTab.MYDRIVE)
                      }}
                      className={activeDriveTab == ActiveDriveTab.MYDRIVE ? activeDriveStyles : inactiveDriveStyles}
                    >
                      My Drive
                    </button>

                    <button
                      onClick={() => {
                        handleActiveDriveTabChange(ActiveDriveTab.SHAREDDRIVE)
                      }}
                      className={activeDriveTab == ActiveDriveTab.SHAREDDRIVE ? activeDriveStyles : inactiveDriveStyles}
                    >
                      Shared Drive
                    </button>
                  </nav>
                </div>
              )}

              {/* Search / Select area */}
              <Combobox
                onChange={(file: FullMetadata) =>
                  dispatch(chatV2CurrentSourceDocumentQueryToggleSelectedFile({ conversationId: conversationId, filePath: file.fullPath }))
                }
              >
                {isFilesNotEmpty && (
                  <Combobox.Input
                    className="w-full rounded-md border-0 bg-gray-100 px-4 py-2.5 text-gray-900 focus:ring-0 sm:text-sm"
                    placeholder="Search..."
                    onChange={(event) => setQuery(event.target.value)}
                  />
                )}

                {loading && <CircularProgressContinuous />}
                <div className={'overflow-y-scroll'}>
                  {keys.length > 0 && (
                    <Combobox.Options static className="-mb-2 max-h-96 sm:max-h-[600px] scroll-py-2 py-2 text-sm text-gray-800">
                      {/* List Folders */}
                      {folders
                        .filter((folder) => folder.name != kGcsOrgHiddenFolder)
                        .map((folder) => {
                          // Create combo box options for every file in this folder
                          const foldersFiles =
                            files.get(folder) != undefined &&
                            files
                              .get(folder)!
                              .filter(
                                (file) => !file.name.toLowerCase().endsWith(kHiddenFileForFolderName) && file.name.toLowerCase().includes(query.toLowerCase())
                              )
                              .filter((file) => file.name.toLowerCase().includes(query.toLowerCase()))
                              .map((file) => {
                                // Mark as processing if the file is not processed and has no error
                                const isProcessingError: boolean = typeof file.customMetadata?.error == 'string' && file.customMetadata?.error != ''
                                const processing: boolean = fileUploadIsProcessing(file)

                                const checked = selectedFiles.some((filePath) => filePath === file.fullPath)

                                // Select Icon
                                function DynamicIconForStatus() {
                                  switch (true) {
                                    case isProcessingError && !processing:
                                      return <ExclamationCircleIcon height={24} className={'mr-1 text-white bg-red-600 rounded-full'} />

                                    case processing:
                                      return (
                                        <div className={'h-[24px] w-[24px] pl-[4px] pt-[2px]'}>
                                          <CircularProgress className={'mr-2'} size={16} thickness={7} />
                                        </div>
                                      )

                                    default:
                                      return <Article />
                                  }
                                }

                                return (
                                  <Combobox.Option
                                    key={file.fullPath}
                                    value={file}
                                    className={`cursor-default select-none px-4 py-2 pl-[49px] grid grid-cols-[24px_auto] items-center border-t-[1px]`}
                                    disabled={processing || isProcessingError}
                                  >
                                    <input
                                      type="checkbox"
                                      checked={checked}
                                      disabled={processing || isProcessingError}
                                      onChange={() => null}
                                      className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600 disabled:bg-gray-100 disabled:cursor-not-allowed"
                                    />

                                    <div className={'pl-1 pr-2 grid grid-cols-[32px_auto] items-center'}>
                                      <DynamicIconForStatus />
                                      <p className={'text-sm'}>{file.name}</p>
                                    </div>
                                  </Combobox.Option>
                                )
                              })

                          function folderChecked(): boolean {
                            // Returns true if all files in this folder are selected
                            if (files.get(folder) == undefined) return false
                            const every = files.get(folder)!.every((file) => selectedFiles.includes(file.fullPath)) ?? false
                            return every
                          }

                          return (
                            <div key={folder.fullPath}>
                              <div className={`cursor-default select-none px-4 py-2 grid grid-cols-[25px_auto] items-center border-t-[1px]`}>
                                <input
                                  type="checkbox"
                                  checked={folderChecked()}
                                  onChange={() => {
                                    console.log('Folder Checkbox Clicked')

                                    // Expand the folder if it is not already expanded
                                    if (!expandedFolders.includes(folder)) {
                                      setExpandedFolders((prev) => [...prev, folder])
                                    }

                                    // If none of this folder's files are loaded, fetch them and select them
                                    if (files.get(folder) == undefined) {
                                      getProcessedFiles({ folder: folder, selectOnLoad: true, driveTab: activeDriveTab })
                                    }

                                    // IF this folder's files are loaded and all are selected, deselect all
                                    else if (folderChecked()) {
                                      // Deselect all files in this folder
                                      for (const file of files.get(folder)!) {
                                        dispatch(
                                          chatV2CurrentSourceDocumentQueryToggleSelectedFile({ conversationId: conversationId, filePath: file.fullPath })
                                        )
                                      }
                                    }

                                    // If this folder's files are loaded, select any that are not already selected
                                    else {
                                      for (const file of files.get(folder)!) {
                                        if (!selectedFiles.includes(file.fullPath))
                                          dispatch(
                                            chatV2CurrentSourceDocumentQueryToggleSelectedFile({ conversationId: conversationId, filePath: file.fullPath })
                                          )
                                      }
                                    }
                                  }}
                                  className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                                />
                                <div className={'pl-1 pr-2 grid grid-cols-[32px_auto_32px] items-center'}>
                                  <Folder />
                                  <p className={'text-sm'}>{folder.name}</p>
                                  {expandedFolders.includes(folder) ? (
                                    <KeyboardArrowUp
                                      onClick={() => setExpandedFolders((prev) => prev.filter((f) => f !== folder))}
                                      className={'cursor-pointer'}
                                    />
                                  ) : (
                                    <KeyboardArrowDown
                                      onClick={() => {
                                        // Expand the folder
                                        setExpandedFolders((prev) => [...prev, folder])

                                        // If the folder's files do not exist in state, fetch the folder's files (don't select them)
                                        if (files.get(folder) == undefined) {
                                          getProcessedFiles({ folder: folder, selectOnLoad: false, driveTab: activeDriveTab })
                                        }
                                      }}
                                      className={'cursor-pointer'}
                                    />
                                  )}
                                </div>
                              </div>

                              {/* Render the combo box options if this folder is open and if there are files */}
                              {expandedFolders.includes(folder) && (foldersFiles ?? null)}
                            </div>
                          )
                        })}

                      {/* List Root Files */}
                      {files.get(null) != undefined &&
                        files
                          .get(null)!
                          .filter((file) => file.name.toLowerCase().includes(query.toLowerCase()))
                          .map((file) => {
                            // Mark as processing if the file is not processed and has no error
                            const isProcessingError: boolean = typeof file.customMetadata?.error == 'string' && file.customMetadata?.error != ''
                            const processing: boolean = fileUploadIsProcessing(file)

                            const checked = selectedFiles.some((filePath) => filePath === file.fullPath)

                            // Select Icon
                            function DynamicIconForStatus() {
                              switch (true) {
                                case isProcessingError && !processing:
                                  return <ExclamationCircleIcon height={24} className={'mr-1 text-white bg-red-600 rounded-full'} />

                                case processing:
                                  return (
                                    <div className={'h-[24px] w-[24px] pl-[4px] pt-[2px]'}>
                                      <CircularProgress className={'mr-2'} size={16} thickness={7} />
                                    </div>
                                  )

                                default:
                                  return <Article />
                              }
                            }

                            return (
                              <Combobox.Option
                                key={file.fullPath}
                                value={file}
                                className={`cursor-default select-none px-4 py-2 grid grid-cols-[24px_auto] items-center border-t-[1px]`}
                                disabled={processing || isProcessingError}
                              >
                                <input
                                  type="checkbox"
                                  disabled={processing || isProcessingError}
                                  checked={checked}
                                  onChange={() => null}
                                  className="h-4 w-4 rounded-sm border-gray-300 text-sky-600 focus:ring-sky-600 disabled:bg-gray-100 disabled:cursor-not-allowed"
                                />

                                <div className={'pl-1 pr-2 grid grid-cols-[32px_auto] items-center'}>
                                  <DynamicIconForStatus />
                                  <p className={'text-sm'}>{file.name}</p>
                                </div>
                              </Combobox.Option>
                            )
                          })}
                    </Combobox.Options>
                  )}
                </div>

                {/* If no files */}
                {isFilesEmpty && (
                  <div className="px-4 py-14 text-center sm:px-14">
                    <AttachFile className="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
                    <p className="mt-4 text-sm text-gray-900">
                      No files ready.{' '}
                      <Link className={'text-sky-600 underline'} to={'/dashboard/files'}>
                        Upload files here.
                      </Link>
                    </p>
                  </div>
                )}
                <div className={'flex'}>
                  <button
                    type={'button'}
                    onClick={() => dispatch(chatV2CurrentSourceDocumentQueryClearAll({ conversationId: conversationId }))}
                    className={
                      'flex-auto items-center rounded-md bg-white border-[1px] border-sky-600 px-3 py-2 m-2 text-sm font-semibold text-sky-600 shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500'
                    }
                  >
                    Clear
                  </button>
                  <button
                    type={'button'}
                    onClick={() => onClose(false)}
                    className={
                      'flex-auto items-center rounded-md bg-sky-600 px-3 py-2 m-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500'
                    }
                  >
                    Done
                  </button>
                </div>
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
