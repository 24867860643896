import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { Article } from '@mui/icons-material'
import { Cross1Icon } from '@radix-ui/react-icons'

import { RootState } from '@/store/store'
import { useAppSelector } from '@/store/store-hooks'
import { selectFileUploadTasksWithKeys } from '@/store/selectors/file-upload-tasks-reducers.selector'
import { extractFileNameFromFullPath } from '@/routes/dashboard/files/files-utils'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'

type FilesPillsProps = {
  file: [string, unknown]
  onRemoveFile: (file: string) => void
}

export default function FilePill({ file, onRemoveFile }: FilesPillsProps) {
  const taskKey = 0
  const taskStatus = 1 // each record in processing status is composed by [key, value] Key being a file source path, and value being the status of the file
  const fileUploadedInformation = useAppSelector((state: RootState) => selectFileUploadTasksWithKeys(state, { keys: [file[taskKey]] }))

  // Inferred Values
  const fileName = fileUploadedInformation[file[taskKey]]?.filePath ?? extractFileNameFromFullPath(file[taskKey])
  const processSuccess = file[taskStatus] === 'success'
  const isError = file[taskStatus] === 'error'

  // Disable cross button when file is processing (not success and not error)
  const disableCrossButton = !processSuccess && !isError

  // Display elements for grid
  function DynamicIcon(): JSX.Element {
    if (isError) {
      return (
        <div className={'self-start mx-auto'}>
          <ExclamationCircleIcon className="rounded-full bg-red-100 text-red-700 size-4 m-1" />
        </div>
      )
    }
    if (!processSuccess) {
      return (
        <div className={'self-start mx-auto mt-1 mb-[2px]'}>
          <CircularProgressContinuousSized size={12} thickness={7} />
        </div>
      )
    }
    return (
      <div className={'self-start mx-auto'}>
        <Article style={{ width: '16px', height: '24px' }} />
      </div>
    )
  }

  return (
    <div
      className={`text-xs rounded grid grid-cols-[24px_auto_24px] items-start ${isError ? 'bg-red-100' : 'bg-gray-200'} ${
        isError ? 'text-red-950' : 'text-gray-900'
      }`}
    >
      <DynamicIcon />

      <div className="self-start text-left pt-[3.5px] pb-1 break-all">{fileName}</div>
      {/* Hide cross when file is processing */}
      <button disabled={disableCrossButton} className={'self-start p-[4px]'} onClick={() => onRemoveFile(file[taskKey])}>
        {/* Show cross icon when not disabled */}
        {!disableCrossButton && (
          <Cross1Icon
            className={`${isError ? 'text-red-950' : 'text-gray-700'} ${
              isError ? 'hover:bg-red-200' : 'hover:bg-gray-300'
            } rounded-full p-[2px] size-4 cursor-pointer float-end`}
          />
        )}
      </button>
    </div>
  )
}
