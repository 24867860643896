import { useAnalytics } from '@/analytics/hooks/useAnalytics'
import { AnalyticsEvent } from '@/analytics/schema/events.schema'
import {
  selectConversationFeature,
  selectConversationIsLoading,
  selectConversationSubmitBlocked,
  selectMessageFromId,
} from '@/chat-common/store/chat-v2.selectors'
import { chatV2HandleSendMessage } from '@/chat-common/util/chat-v2-handle-send-message'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { RootState } from '@/store/store'
import { useAppSelector } from '@/store/store-hooks'
import { useState } from 'react'

type ChatSuggestedQuestionsBubbleProps = {
  chatId: string
  messageId: string
}

export default function ChatSuggestedQuestionsBubble(props: ChatSuggestedQuestionsBubbleProps) {
  const { chatId, messageId } = props
  const { trackEvent } = useAnalytics()

  // Redux State Selectors
  const submitBlocked = useAppSelector((state: RootState) => selectConversationSubmitBlocked(state, { chatId }))
  const conversationIsLoading = useAppSelector((state: RootState) => selectConversationIsLoading(state, { chatId }))
  const feature = useAppSelector((state: RootState) => selectConversationFeature(state, { chatId }))
  const message = useAppSelector((state: RootState) =>
    selectMessageFromId(state, {
      messageId,
      chatId,
    })
  )

  // Local state
  const [clickedSuggestion, setClickedSuggestion] = useState<boolean>(false)

  // Inferred values
  const loading = message.metadata.suggested_questions_loading
  const isError = message.metadata.is_error
  const allSuggestions = message.metadata.suggested_questions?.suggestions ?? []

  // Trim to a max of 3 suggestions (if there are more)
  const trimmedSuggestions = allSuggestions.length > 3 ? allSuggestions.slice(0, 3) : allSuggestions

  return (
    <div className={`pr-2 py-0 mt-0 pb-5 whitespace-pre-wrap `}>
      <div className={'border-l border-gray-300 ml-5 pl-5 py-1'}>
        <div className={`font-bold mb-1 text-xs text-gray-800`}>Follow up questions</div>
        {loading && (
          <div className={'flex items-center gap-x-2'}>
            <CircularProgressContinuousSized size={14} thickness={7} hexColor={'#0285c7'} />{' '}
            <p className={'inline text-xs'}>Generating follow up questions...</p>
          </div>
        )}

        {isError && <div className={'text-red-700 text-xs pb-1'}>Error generating follow up questions.</div>}

        {/* User has tried clicking a suggested question but it is blocked */}
        {clickedSuggestion && submitBlocked && !conversationIsLoading && (
          <div className={'text-red-700 text-xs pb-1'}>Please correct any form errors before selecting a follow up question.</div>
        )}

        <ol className={'list-disc pl-4'}>
          {trimmedSuggestions.length > 0 &&
            trimmedSuggestions.map((suggestion) => {
              return (
                <li
                  key={`${message.metadata.message_id}_${suggestion.suggestion_number}`}
                  className={`mb-1 last-of-type:mb-0 leading-none ${conversationIsLoading ? '' : ''}`}
                >
                  <button
                    onClick={() => {
                      setClickedSuggestion(true)

                      // Clicking is  enabled to allow the user to click it so that we can show them an error message alerting them to form requirements. via setClickedSuggestion()
                      if (conversationIsLoading) return
                      if (!submitBlocked) {
                        chatV2HandleSendMessage({
                          chatId,
                          onComplete: () => {
                            // Track the new message
                            trackEvent(AnalyticsEvent.NewChatMessage, { feature: feature })
                          },
                          messageOverride: suggestion.suggestion,
                        })
                        setClickedSuggestion(false)
                      }
                    }}
                    className={`text-xs text-left underline ${
                      conversationIsLoading || submitBlocked
                        ? 'text-gray-400 hover:text-gray-400 no-underline cursor-default'
                        : 'cursor-pointer text-sky-700 hover:text-sky-600'
                    }`}
                  >
                    {suggestion.suggestion}
                  </button>
                </li>
              )
            })}
        </ol>
      </div>
    </div>
  )
}
