import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { chatV2RemoveVisibleReference } from '@/chat-common/store/chat-v2.slice'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import referenceMetadataToTitle from '@/chat-common/util/reference-metadata-to-title'
import { Cross2Icon, ExternalLinkIcon } from '@radix-ui/react-icons'
import ReferenceViewerConstructor from './reference-viewer-constructor'
import { downloadReferenceAsDocX } from '@/chat-common/util/download-conversation'
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { useAnalytics } from '@/analytics/hooks/useAnalytics'
import { AnalyticsEvent } from '@/analytics/schema/events.schema'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { Link } from 'react-router-dom'
import { isCitatorEnabled } from './reference-view-utils'
import { RootState } from '@/store/store'
import { selectConversationFeature, selectVisibleReference } from '@/chat-common/store/chat-v2.selectors'

type ReferenceViewerSlideoverProps = {
  open: boolean
  conversationId: string
}

export default function ReferenceViewerSlideover(props: ReferenceViewerSlideoverProps) {
  const { open, conversationId } = props
  const { trackEvent } = useAnalytics()
  const dispatch = useAppDispatch()

  // Feature
  const feature = useAppSelector((state: RootState) => selectConversationFeature(state, { chatId: conversationId }))

  // Local state
  const [referenceHtml, setReferenceHtml] = useState('')
  const [downloadLoading, setDownloadLoading] = useState(false)

  // Active reference
  const visibleReference = useAppSelector((state: RootState) => selectVisibleReference(state, { chatId: conversationId }))
  if (!visibleReference) return null

  // Inferred values
  const rawTitle = visibleReference.metadata?.title
  const parentId = visibleReference.metadata?.parent_id

  // Generate reference title
  const title = referenceMetadataToTitle(visibleReference)

  // Render
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          dispatch(chatV2RemoveVisibleReference({ conversationId }))
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-5">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <div className="bg-white flex-grow flex flex-col h-full">
                    <button
                      className={'float-right z-50 fixed top-1 right-2 border p-1 border-gray-50 rounded-full bg-white hover:bg-gray-100'}
                      onClick={() => dispatch(chatV2RemoveVisibleReference({ conversationId }))}
                    >
                      <Cross2Icon className={'w-6 h-6'} />
                    </button>

                    <div className="flex flex-grow flex-col h-full w-full p-2">
                      <div className={'flex gap-x-4 items-end text-normal font-bold mb-2 border-b pb-2'}>
                        [{visibleReference.reference_number}]
                        {parentId && (
                          <Link
                            to={`/dashboard/reference-viewer/caselaw/${parentId}`}
                            target="_blank"
                            className={'flex items-end hover:text-sky-700 active:bg-sky-100'}
                          >
                            <span className={'text-sm font-medium leading-tight'}>New tab</span> <ExternalLinkIcon className={'w-4 h-4 pl-[4px] pb-[0px]'} />
                          </Link>
                        )}
                        <button
                          className={`flex items-end hover:text-sky-700 active:bg-sky-100 ${referenceHtml ? '' : 'hidden'}`}
                          onClick={() => {
                            trackEvent(AnalyticsEvent.DownloadChatSourceToDocx)
                            downloadReferenceAsDocX(referenceHtml, rawTitle ?? 'source', setDownloadLoading)
                          }}
                        >
                          <span className={'text-sm font-medium leading-tight'}>.docx</span>
                          <ArrowDownTrayIcon className={'h-4 pl-[4px] pb-[2px]'} />
                          {downloadLoading && (
                            <span className={'pl-2 pb-[1px] h-5'}>
                              <CircularProgressContinuousSized size={12} thickness={7} />
                            </span>
                          )}
                        </button>
                      </div>
                      {/* Border and spacing compensation for citator button */}
                      <div className={`text-sm whitespace-pre-wrap break-all font-bold  ${isCitatorEnabled(feature, visibleReference) ? '' : 'pb-2 border-b'}`}>
                        {title}
                      </div>
                      <ReferenceViewerConstructor conversationId={conversationId} setReferenceHtmlCallback={setReferenceHtml} />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
