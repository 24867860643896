import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import CaseLawFederalDialogCourtSelection from './caselaw-federal-dialog-court-selection'
import { chatV2AddCaseLawCourtIds, chatV2RemoveCaseLawCourtIds } from '@/chat-common/store/chat-v2.slice'
import { CourtsResponse } from '@/store/apis/hosted-filters-api'
import { useAppDispatch } from '@/store/store-hooks'
import { getAllFederalCourtIds } from './caselaw-court-utils'

type CaseLawFederalDialogProps = {
  open: boolean
  onClose: (value: boolean) => void
  conversationId: string
  courts: CourtsResponse
}

export default function CaseLawFederalDialog(props: CaseLawFederalDialogProps) {
  const { open, onClose, conversationId, courts } = props

  // Store
  const dispatch = useAppDispatch()

  // Toggle All  Courts
  function toggleAllCourts(add: boolean) {
    const courtIds = getAllFederalCourtIds(courts)

    if (add) {
      dispatch(chatV2AddCaseLawCourtIds({ conversationId: conversationId, courtIds: courtIds }))
    } else {
      dispatch(chatV2RemoveCaseLawCourtIds({ conversationId: conversationId, courtIds: courtIds }))
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto sm:px-4 py-20 lg:ml-[300px]">
          <div className="flex items-end justify-center p-2 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl sm:p-6">
                <div>
                  <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                    Federal Courts
                  </Dialog.Title>
                  <div className="px-1 mt-2 overflow-y-scroll max-h-96 sm:max-h-[600px]">
                    <CaseLawFederalDialogCourtSelection conversationId={conversationId} courts={courts} />
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-3 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600 sm:col-start-3"
                    onClick={() => onClose(false)}
                  >
                    Done
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-2 sm:mt-0"
                    onClick={() => {
                      toggleAllCourts(true)
                    }}
                  >
                    Select All
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={() => {
                      toggleAllCourts(false)
                    }}
                  >
                    Clear
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
