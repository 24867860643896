import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { RootState } from '@/store/store'
import { deleteFileUploadTask, removeFileProcessing } from '@/store/slices/file-upload-tasks.slice'
import { selectProcessingStatusesForSelectedFiles, selectSelectedFiles } from '@/chat-common/store/chat-v2.selectors'
import FilePill from './file-pill'
import { chatV2CurrentSourceAssistantToggleSelectedFile } from '@/chat-common/store/chat-v2.slice'
import { ExclamationTriangleIcon } from '@radix-ui/react-icons'

type SelectedFilesProps = {
  chatId: string
  isOverLimit: boolean
}

function SelectedFilesContent(props: SelectedFilesProps) {
  const { chatId, isOverLimit } = props
  const dispatch = useAppDispatch()

  // Selected Files State
  const selectedFilePaths = useAppSelector((state: RootState) => selectSelectedFiles(state, { chatId }))
  // Combined selector
  const selectedFileProcessingStatuses = useAppSelector((state: RootState) =>
    selectProcessingStatusesForSelectedFiles(state, {
      keys: selectedFilePaths,
      chatId: chatId,
    })
  )

  const removeFile = (fileKey: string) => {
    // Delete upload task
    dispatch(removeFileProcessing({ sourcePath: fileKey }))
    // Remove from processing queue
    dispatch(deleteFileUploadTask({ sourcePath: fileKey }))
    // Remove from selected files
    dispatch(chatV2CurrentSourceAssistantToggleSelectedFile({ conversationId: chatId, filePath: fileKey, remove: true }))
  }

  return (
    <div className="relative top-3 z-5 w-full bg-gray-50 border border-b-0 rounded-t-lg opacity-100 transition-opacity duration-300 ease-linear">
      {isOverLimit && (
        <div className="p-2">
          <div className="rounded-lg bg-yellow-50 p-2 grid grid-cols-[45px_auto] items-center">
            <div className={'flex h-7 w-7 text-yellow-700 bg-yellow-50 rounded-lg p-1 ring-4 ring-white items-center justify-center'}>
              <ExclamationTriangleIcon />
            </div>
            <span className={'text-xs text-yellow-900 text-opacity-70'}>
              {/* Large files, or a large number of files may impact the accuracy of {brandedAIFriendlyName}'s response. */}
              Reducing the amount of content may improve response quality.
            </span>
          </div>
        </div>
      )}
      <div className="flex w-full flex-wrap gap-2 p-2 justify-items-start overflow-y-auto max-h-24">
        {Object.entries(selectedFileProcessingStatuses).map((item, i) => (
          <FilePill key={`${item}-${i}`} file={item} onRemoveFile={removeFile} />
        ))}
      </div>
    </div>
  )
}

export default SelectedFilesContent
