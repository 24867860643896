import { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { kAcceptedImageFormats } from '@/constants/constants-gcs'
import { UploadTaskStatus } from '@/routes/dashboard/files/FilesDropZone'
import { StorageReference } from 'firebase/storage'
import { Image } from '@mui/icons-material'
import { fileToPngBlob } from '@/organizations/services/image-edit-functions'
import { UploadDisclaimer } from '@/constants/constants-components'

export type ChangeLogoDropZoneProps = {
  onTaskListItemUpdated: (task: UploadTaskStatus, allTasks: Map<string, UploadTaskStatus>) => void
  currentFolder: StorageReference | null
  onImageBlobPrepared: (blob: Blob) => void
  singleFileLimit?: boolean
  showFolderName?: boolean
}

export default function ChangeLogoDropZone(props: ChangeLogoDropZoneProps) {
  const { onTaskListItemUpdated, onImageBlobPrepared, currentFolder, singleFileLimit } = props

  const [dropError, setDropError] = useState<string | null>(null)

  // On Drop
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setDropError(null)

      if (acceptedFiles.length === 0) {
        console.log('No accepted files to upload.')
        return
      }

      acceptedFiles.forEach(async (file: File) => {
        // Convert the file to a canvas element and scale the size
        const blob: Blob = await fileToPngBlob(file, 400, 250)

        // Call the onFileAccepted callback with the resized file
        onImageBlobPrepared(blob)
      })
    },
    [currentFolder, onTaskListItemUpdated] // depend on this and use latest version when running
  )

  // On Drop Rejected
  const onDropRejected = (fileRejections: any) => {
    console.log('FileRejections: ', fileRejections)
    if (singleFileLimit && fileRejections.length > 1) {
      setDropError('Please upload a single image.')
      return
    }

    fileRejections.forEach(() => {
      // Do nothing
      // const extensionMatch = fileRejection?.file?.path.match(/\.[0-9a-z]+$/i)
      // const extension = extensionMatch ? extensionMatch[0] : 'unknown'
      // const errorMessage = `Unsupported file type ${extension}`
    })
  }

  // Dropzone
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    accept: kAcceptedImageFormats,
    maxFiles: singleFileLimit ? 1 : undefined,
  })

  // Render
  return (
    <>
      <div
        className={`p-4 rounded-lg border-2 border-sky-600 transition-all duration-300 ${isDragActive ? 'bg-sky-50' : 'border-opacity-20 '}  border-dashed`}
        {...getRootProps()}
      >
        <input {...getInputProps()} />

        <div className={'text-center'}>
          <div className={'mb-3s'}>
            <Image />
          </div>
          <p>
            Drag & Drop or <span className={'text-sky-600 underline cursor-pointer'}>Choose{singleFileLimit ? ' an Image' : ' Images'}</span>
          </p>
          <p className={'mt-1 text-sm text-gray-500'}>Supported formats {Object.values(kAcceptedImageFormats).flat().join(', ')}</p>
          <UploadDisclaimer />
          {dropError && <p className={'mt-1 text-sm text-red-700'}>{dropError}</p>}
        </div>
      </div>
    </>
  )
}
