import * as Popover from '@radix-ui/react-popover'
import { useState } from 'react'
import { ChatV2QueryMetadataAssistant, ModeChoice } from '@/chat-common/schemas/chat-query-metadata-schema'
import { assistantModes } from '@/chat-common/components/form-source-controls/assistant/assistant-modes'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { RootState } from '@/store/store'
import { selectConversationCurrentSource } from '@/chat-common/store/chat-v2.selectors'
import { chatV2CurrentSourceAssistantSetFocus } from '@/chat-common/store/chat-v2.slice'
import { ArrowDropDown } from '@mui/icons-material' // Material UI dropdown icon

type ModeSelectorProps = {
  conversationId: string
}

export default function ModeSelector(props: ModeSelectorProps) {
  const { conversationId } = props
  const dispatch = useAppDispatch()

  // Redux selectors
  const currentSource = useAppSelector((state: RootState) =>
    selectConversationCurrentSource(state, { chatId: conversationId })
  ) as ChatV2QueryMetadataAssistant | null

  // Local state
  const [open, setPopoverOpen] = useState<boolean>(false)

  // Get the current mode from the current source, fallback to default mode if not set
  const mode = currentSource?.focus_mode ?? ModeChoice.ASSISTANT

  // Extract the selected option and its icon
  const selectedOption = assistantModes.find((option) => option.value === mode)
  const IconComponent = selectedOption?.icon

  // Handle selection
  const handleSelection = (value: ModeChoice) => {
    dispatch(chatV2CurrentSourceAssistantSetFocus({ conversationId: conversationId, focusMode: value }))
    setPopoverOpen(false)
  }

  return (
    <div className="inline-flex items-center -ml-1 bg-gray-50 border border-gray-400 rounded-lg overflow-hidden">
      <span className="px-3 py-2 font-medium text-gray-500 text-xs bg-gray-50">Mode</span>
      <Popover.Root open={open} onOpenChange={setPopoverOpen}>
        <Popover.Trigger asChild>
          <button
            className="cursor-pointer flex items-center justify-between border-l border-gray-400 bg-sky-50 px-2 py-2 text-sm font-medium transition-all"
            aria-label="Choose the mode of your query"
            title="Mode"
          >
            {IconComponent && <IconComponent fontSize="small" className="mr-1 text-sky-600" />}
            <span className="text-xs font-medium text-sky-600">{selectedOption?.title}</span>
            <ArrowDropDown className="ml-2 text-gray-700" fontSize="small" />
          </button>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            onEscapeKeyDown={() => setPopoverOpen(false)}
            onInteractOutside={() => setPopoverOpen(false)}
            side="bottom"
            align="start"
            alignOffset={-58}
            sideOffset={-45}
            className="PopoverContent z-50 min-w-[260px] mr-3 px-2 py-2 bg-white rounded-md shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.75),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.5)]"
          >
            <div className="flex flex-col gap-1">
              <h2 className="text-[14px] mt-1 text-center font-semibold text-gray-600">Mode</h2>
              {assistantModes.map((option, i) => (
                <button
                  key={option.value + '_' + i}
                  className={`flex p-2 items-center text-left rounded-md hover:bg-sky-50 ${option.value === mode ? 'text-sky-600' : 'text-gray-700'}`}
                  onClick={() => handleSelection(option.value as ModeChoice)}
                  aria-pressed={option.value === mode}
                >
                  {option.icon && <option.icon />}
                  <div>
                    <div className="ml-2 text-[12px] font-semibold w-[75px]">{option.title}</div>
                    <p className="ml-2 text-[12px]">{option.description}</p>
                  </div>
                </button>
              ))}
            </div>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  )
}
