import React from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import CheckoutForm from './Checkout'
import { CircularProgressContinuous } from '@/components/loaders/CircularProgressContinuous'

type CheckoutContainerProps = {
  clientSecret: string | null
  checkoutButtonCopy?: string
  supportTrial?: boolean
}

const CheckoutContainer: React.FC<CheckoutContainerProps> = ({ clientSecret, checkoutButtonCopy, supportTrial }) => {
  const appearance = {
    theme: 'stripe' as const,
    variables: {
      colorPrimary: '#0ea5e9',
      colorBackground: '#fff',
      colorText: '#374151',
      colorDanger: '#b91c1c',
      fontFamily: 'ui-sans-serif, system-ui, sans-serif',
    },
  }

  if (!clientSecret) {
    return <CircularProgressContinuous />
  }

  const options = {
    clientSecret,
    appearance,
  }

  return (
    <Elements options={options} stripe={loadStripe(import.meta.env.VITE_PUBLISHABLE_STRIPE_KEY)}>
      <CheckoutForm checkoutButtonCopy={checkoutButtonCopy} supportTrial={supportTrial} />
    </Elements>
  )
}

export default CheckoutContainer
