import { ChevronUpIcon, ChevronDownIcon } from '@radix-ui/react-icons'
import { Link } from 'react-router-dom'
import { LockClosedIcon } from '@heroicons/react/24/outline'
import { NavigationItem } from './dashboard-sidebar'
import { useRef, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { selectSidebarItemState } from '@/store/selectors/ui-state.selectors'
import { setSidebarItemOpen, SidebarItemId } from '@/store/slices/ui-state.slice'

type SidebarProps = {
  title: string
  items: NavigationItem[]
  isAnonUser: boolean
  setSidebarOpen: (open: boolean) => void
  itemId: SidebarItemId
}

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ')
}

const CollapsibleItem = (props: SidebarProps) => {
  const { title, items, isAnonUser, setSidebarOpen, itemId } = props
  const dispatch = useAppDispatch()

  // Select the open state for this specific item using the selector
  const sidebarItemState = useAppSelector(selectSidebarItemState(itemId))
  const isOpen = !!sidebarItemState?.isOpen

  const handleOpenChange = () => {
    dispatch(setSidebarItemOpen(itemId))
  }

  // Ref to access the collapsible content DOM element
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.overflow = 'hidden'
      contentRef.current.style.transition = 'height 200ms ease-out'

      // Create a ResizeObserver to monitor changes to the content's size
      const observer = new ResizeObserver(() => {
        if (isOpen && contentRef.current) {
          // Update the height to match the content's scrollHeight when it changes
          const scrollHeight = contentRef.current.scrollHeight
          contentRef.current.style.height = `${scrollHeight}px`
        }
      })

      observer.observe(contentRef.current)

      if (isOpen) {
        const scrollHeight = contentRef.current.scrollHeight
        contentRef.current.style.height = `${scrollHeight}px`
      } else {
        contentRef.current.style.height = '0px'
      }

      // Cleanup function to disconnect the observer when the component unmounts or dependencies change
      return () => {
        observer.disconnect()
      }
    }
  }, [isOpen, items.length])

  const chevronIconStyles = 'hover:text-sky-600 self-center h-4 w-4'

  return (
    <div className="w-full mb-2">
      <button onClick={handleOpenChange} className="flex w-full pr-2 justify-between">
        <span className="text-[15px] font-semibold leading-[25px]">{title}</span>
        {isOpen ? <ChevronUpIcon className={chevronIconStyles} /> : <ChevronDownIcon className={chevronIconStyles} />}
      </button>
      {/* Collapsible content */}
      <div ref={contentRef}>
        <div>
          {items.map((item, i) => (
            <Link
              to={item.href}
              onClick={() => setSidebarOpen(false)}
              key={`${i}-${item.name}`}
              className={classNames(
                item.current ? 'bg-gray-50 text-sky-600' : 'text-gray-700 hover:text-sky-600 hover:bg-gray-50',
                'group flex gap-x-3 items-center px-2 py-1 text-sm leading-6',
                item.subItem ? 'ml-4 pl-1.5 border-l-[1px] border-l-gray-200' : ''
              )}
            >
              <item.icon
                fontSize="small"
                className={classNames(item.current ? 'text-sky-600' : 'text-gray-400 group-hover:text-sky-600', item.subItem ? 'h-4 w-4' : 'h-5 w-5')}
                aria-hidden="true"
              />
              <span className={item.subItem ? 'text-xs' : ''}>{item.name}</span>
              {isAnonUser && !item.allowAnon && <LockClosedIcon className="h-4 ml-auto text-gray-400" />}
              {!isAnonUser && item.new && (
                <div className="text-2xs leading-none ml-auto pt-[1.5px] pb-[2.5px] px-[5px] rounded-full bg-purple-500 text-white">beta</div>
              )}
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}

export default CollapsibleItem
