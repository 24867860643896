import { ChevronDownIcon, ChevronRightIcon } from '@radix-ui/react-icons'
import ChatMessageBubbleReference from './chat-message-bubble-reference'

type ReferencesSectionProps = {
  title: string
  references: string[]
  isExpanded: boolean
  toggleExpand: () => void
  conversationId: string
  messageId: string
}

export default function ReferencesSection({ title, references, isExpanded, toggleExpand, conversationId, messageId }: ReferencesSectionProps) {
  return (
    <>
      <div className={`px-1 text-sm text-gray-500 flex items-center gap-x-2 cursor-pointer rounded-md hover:bg-gray-100`} onClick={toggleExpand}>
        {references.length} {title}
        <div>{isExpanded ? <ChevronDownIcon width="16" height="16" /> : <ChevronRightIcon width="16" height="16" />}</div>
      </div>
      {isExpanded && (
        <div className={`text-sm mt-2 px-1`}>
          {references.map((referenceKey) => (
            <ChatMessageBubbleReference key={referenceKey} conversationId={conversationId} messageId={messageId} referenceKey={referenceKey} />
          ))}
        </div>
      )}
    </>
  )
}
