import { ChatV2MessageReferenceType } from '@/chat-common/store/chat-v2.slice'
import InlineTipError from '@/components/inline-tips/InlineTipError'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { kCaseLawSourceSanitizationAllowedTags } from '@/constants/constants-ui'
import { findAll } from 'highlight-words-core'
import { Parser } from 'html-to-react'
import { useEffect, useRef, Dispatch, SetStateAction, useState } from 'react'
import sanitizeHtml from 'sanitize-html'
import { escapeSpecialCharsFromHtmlSourceForSnippet, scrollToFirstHighlight } from './reference-view-utils'
import CaseLawReferenceCitatorTab from '@/citator/views/CaseLawReferenceCitatorTab'
import CaseLawReferenceRunCitatorButton from '@/citator/views/CaseLawRunCitatorReferenceButton'
import { CaseLawReferenceViewTabs } from './reference-uri-handler'
import { useGetCaselawReferenceHtmlQuery } from '@/standalone-reference-viewer/caselaw/apis/caselaw-reference-viewer.api'
import { brandedAIName } from '@/util/enterprise'
import { RootState } from '@/store/store'
import { useAppSelector } from '@/store/store-hooks'
import { selectVisibleReference, selectVisibleReferenceRenderTrigger } from '@/chat-common/store/chat-v2.selectors'

type ReferenceViewCaseLawProps = {
  conversationId: string
  setReferenceHtmlCallback?: Dispatch<SetStateAction<string>>
}

function sanitizeHighlightHtml(htmlString: string | undefined, reference: ChatV2MessageReferenceType | null): string {
  if (!htmlString) return ''
  if (!reference) return htmlString

  // Sanitize the string
  const sanitizedHtml = sanitizeHtml(htmlString, {
    allowedTags: kCaseLawSourceSanitizationAllowedTags,
    transformTags: { pre: 'p', code: 'p' },
  })

  // Generate highlight snippets from the text source
  const snippets = reference.text
    .split('\n')
    .map((s) => s.trim())
    .slice(2)
    .join(' ')
    .split('.')
    .filter((sentence) => sentence.length > 45)
    .map((sentence) => escapeSpecialCharsFromHtmlSourceForSnippet(sentence))

  // Find all snippets to highlight
  const chunks = findAll({
    caseSensitive: false,
    searchWords: snippets,
    textToHighlight: sanitizedHtml,
  })

  // Format the HTML to wrap highlighted snippets in a span with highlight styles
  const highlightedHtml = chunks
    .map((chunk: { end: any; highlight: any; start: any }) => {
      const { end, highlight, start } = chunk
      const text = sanitizedHtml.slice(start, end)
      if (highlight) {
        return `<span class="highlighted-snippet bg-yellow-300">${text}</span>`
      } else {
        return text
      }
    })
    .join('')

  return highlightedHtml
}

export default function ReferenceViewContentCaseLawV2(props: ReferenceViewCaseLawProps) {
  const { conversationId, setReferenceHtmlCallback } = props

  // Redux state values
  const reference = useAppSelector((state: RootState) => selectVisibleReference(state, { chatId: conversationId }))
  const renderTrigger = useAppSelector((state: RootState) => selectVisibleReferenceRenderTrigger(state, { chatId: conversationId }))
  const openToCitatorTab = useAppSelector((state: RootState) => state.chatV2State.conversations[conversationId]?.openRefToCitatorTab)

  // Inferred values
  const parentId = reference?.metadata.parent_id
  console.log('Rendering caselaw reference (v2): ', reference)

  // Local state
  const sourceContainerRef = useRef<HTMLDivElement>(null)
  const defaultToCitatorTab = openToCitatorTab ?? false
  const [activeTab, setActiveTab] = useState<CaseLawReferenceViewTabs>(defaultToCitatorTab ? CaseLawReferenceViewTabs.citator : CaseLawReferenceViewTabs.source)

  // Styles
  const inactiveTabStyles =
    'grow border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium'
  const activeTabStyles = ' grow border-sky-500 text-sky-600 w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium'

  // Handle tab change
  function handleActiveTabChange(tab: CaseLawReferenceViewTabs) {
    setActiveTab(tab)
  }

  // RTK-Query: load the HTML source
  const { data, isLoading, isError, refetch } = useGetCaselawReferenceHtmlQuery(parentId ?? '')
  const htmlData = data?.data

  // On reference change, reset the active tab
  useEffect(() => {
    setActiveTab(defaultToCitatorTab ? CaseLawReferenceViewTabs.citator : CaseLawReferenceViewTabs.source)
  }, [reference])

  // Scroll to the first highlighted portion whenever the HTML string changes
  useEffect(() => {
    scrollToFirstHighlight(sourceContainerRef, '.highlighted-snippet')
  }, [htmlData, renderTrigger])

  useEffect(() => {
    if (setReferenceHtmlCallback) {
      // Format the HTML
      const highlightedHtml = sanitizeHighlightHtml(htmlData, reference)
      setReferenceHtmlCallback(highlightedHtml)
    }
  }, [htmlData, renderTrigger, reference, setReferenceHtmlCallback])

  // If no reference
  if (!reference) return <div>No reference selected</div>

  // Show loading indicator
  if (isLoading) return <CircularProgressContinuousSized size={18} thickness={7} />

  // Show error
  if (isError || !htmlData)
    return (
      <>
        {InlineTipError('Could not load this reference.')}
        <div>
          <button
            className={
              'inline rounded-md bg-sky-600 border-[1px] border-sky-600 px-3 py-2 m-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500'
            }
            onClick={() => refetch()}
          >
            Retry
          </button>
        </div>
      </>
    )

  // Configure the HTML parser
  const htmlParser = Parser()

  return (
    <>
      {/* Citator Status */}
      <div className={'pb-2'}>
        <CaseLawReferenceRunCitatorButton
          parent_id={parentId}
          tailwindTextSize="text-sm"
          onClickCallback={() => {
            handleActiveTabChange(CaseLawReferenceViewTabs.citator)
          }}
        />
      </div>

      {/* Tabs */}
      <div className="border-b border-gray-200 mb-2">
        <nav className="w-full flex items-center" aria-label="Tabs">
          <button
            onClick={() => {
              handleActiveTabChange(CaseLawReferenceViewTabs.source)
            }}
            className={activeTab == CaseLawReferenceViewTabs.source ? activeTabStyles : inactiveTabStyles}
          >
            Source View
          </button>
          <button
            onClick={() => {
              handleActiveTabChange(CaseLawReferenceViewTabs.citator)
            }}
            className={activeTab == CaseLawReferenceViewTabs.citator ? activeTabStyles : inactiveTabStyles}
          >
            {brandedAIName} Citator
          </button>
        </nav>
      </div>

      {/* Render Source */}
      <div
        id="case-law-source"
        ref={sourceContainerRef}
        className={`text-sm reference-view-html overflow-y-scroll ${activeTab != CaseLawReferenceViewTabs.source ? 'hidden' : ''}`}
      >
        {htmlParser.parse(sanitizeHighlightHtml(htmlData, reference))}
      </div>
      {/* Render Citator */}
      {activeTab === CaseLawReferenceViewTabs.citator && (
        <div className={`overflow-y-scroll`}>
          <CaseLawReferenceCitatorTab parent_id={parentId} />
        </div>
      )}
    </>
  )
}
