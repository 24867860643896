import React from 'react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { DocumentArrowDownIcon, EllipsisVerticalIcon, TrashIcon, XCircleIcon, PencilSquareIcon } from '@heroicons/react/24/outline'
import { downloadConversationAsDocxRemote } from '@/chat-common/util/download-conversation.tsx'
import { setRetitleConversationVisible } from '@/store/slices/ui-state.slice'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { useAnalytics } from '@/analytics/hooks/useAnalytics'
import { AnalyticsEvent } from '@/analytics/schema/events.schema'
import { useParams } from 'react-router-dom'
import { selectConversationFromId } from '../store/chat-v2.selectors'
import { RootState } from '@/store/store'

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

type Props = {
  conversationId: string
  onSelectDelete: () => void
}

const ChatListSidebarChatItemMenu: React.FC<Props> = (props: Props) => {
  const { conversationId, onSelectDelete } = props
  const { trackEvent } = useAnalytics()
  const dispatch = useAppDispatch()
  const { chatId } = useParams()

  // This is active if the chatId in the URL matches the conversationId
  const chatItemActive = chatId === conversationId

  // Watch this conversation state for changes because the menu items depend on it
  const conversation = useAppSelector((state: RootState) => selectConversationFromId(state, { chatId: conversationId }))

  // Menu elements
  const menuElements = [
    {
      hideFromAnon: true,
      name: 'Cancel',
      icon: XCircleIcon,
      current: false,
      onClick: () => {
        return
      },
    },
    {
      hideFromAnon: true,
      name: 'Delete',
      icon: TrashIcon,
      current: false,
      onClick: () => {
        trackEvent(AnalyticsEvent.OpenConversationDeletionDialog)
        onSelectDelete()
      },
    },
    {
      name: 'Rename',
      icon: PencilSquareIcon,
      onClick: () => {
        trackEvent(AnalyticsEvent.OpenConversationRetitleDialog)
        dispatch(
          setRetitleConversationVisible({
            visible: true,
            conversationId: conversationId,
          })
        )
      },
    },
    {
      name: 'Download .docx',
      icon: DocumentArrowDownIcon,
      onClick: () => {
        trackEvent(AnalyticsEvent.DownloadConversationToDocx)
        downloadConversationAsDocxRemote(conversation)
      },
    },
  ]

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <button
          className={`absolute outline-none right-0 top-0 py-1 px-0 rounded-md ${
            chatItemActive ? 'hover:bg-sky-800 hover:bg-opacity-15' : 'hover:bg-gray-200'
          } transition-all duration-200 ease-in-out`}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
          }}
        >
          <EllipsisVerticalIcon
            onClick={() => {
              console.log('clicked')
            }}
            width={18}
            height={18}
          />
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className="z-50 min-w-[200px] bg-white rounded-md p-[5px] shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.75),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.5)] will-change-[opacity,transform] data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade"
          sideOffset={5}
        >
          <ul role="list" className="space-y-1">
            {menuElements.map((item) => (
              <li key={item.name}>
                <DropdownMenu.Item
                  onClick={(e) => {
                    e.stopPropagation()
                    item.onClick()
                  }}
                  className={classNames(
                    item.current ? 'bg-gray-50 text-sky-600' : 'text-gray-700 hover:text-sky-600 hover:bg-gray-50',
                    'rounded-md group flex items-center flex-nowrap gap-x-1 py-[4px] text-sm leading-6 cursor-pointer w-full'
                  )}
                >
                  <item.icon
                    className={classNames(item.current ? 'text-sky-600' : 'text-gray-400 group-hover:text-sky-600', 'h-5 w-5 shrink-0')}
                    aria-hidden="true"
                    viewBox="0 0 28 24"
                  />
                  <div className={`whitespace-nowrap text-sm`}>{item.name}</div>
                </DropdownMenu.Item>
              </li>
            ))}
          </ul>
          <DropdownMenu.Arrow className="fill-white" />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}

export default ChatListSidebarChatItemMenu
