import { configureStore } from '@reduxjs/toolkit'
import chatV2Slice from '@/chat-common/store/chat-v2.slice'
import chatV2UxSlice from '@/chat-common/store/chat-v2-ux.slice'
import aiQueryResultSlice from './slices/ai-query-result.slice'
import documentAuditStreamResponseSlice from './slices/document-audit-stream-response.slice'
import booleanBuilderStreamResponseSlice from './slices/boolean-builder-stream-response.slice'
import globalToastSlice from './slices/global-toast.slice'
import uiStateSlice from './slices/ui-state.slice'
import { paxtonAppApi } from './apis/paxton-app-api'
import { chatV2Api } from '@/chat-common/chat-v2-api'
import onboardingReducer, { onboardingApi } from '@/onboarding/store/onboarding.slice'
import { getSourceApi } from '@/chat-common/api/source-apis'
import documentEditingSlice from '@/document-editing/store/document-editing.slice'
import { documentEditingApi } from '@/document-editing/apis/document-editing.api'
import { hostedFiltersApi } from './apis/hosted-filters-api'
import { orgAdminApi } from '@/organizations/apis/org-admin.api'
import citatorStateSlice from '@/citator/store/citator-state.slice'
import { citatorApi } from '@/citator/repository/citiator.api'
import { caselawReferenceViewerApi } from '@/standalone-reference-viewer/caselaw/apis/caselaw-reference-viewer.api'
import subscriptionReducer, { subscriptionApi } from '../routes/dashboard/subscribe/store/subscription.slice'
import appInitSlice from './slices/app-init.slice'
import uploadTasksSlice from './slices/file-upload-tasks.slice'
import { limitApi } from '@/routes/dashboard/files/apis/limit.api'

export const store = configureStore({
  reducer: {
    chatV2State: chatV2Slice,
    chatV2UxState: chatV2UxSlice,
    uploadFileTasksState: uploadTasksSlice,
    aiQueryResults: aiQueryResultSlice,
    documentAuditApiResponse: documentAuditStreamResponseSlice,
    booleanBuilderApiResponse: booleanBuilderStreamResponseSlice,
    globalToast: globalToastSlice,
    uiState: uiStateSlice,
    appInitState: appInitSlice,
    documentEditingState: documentEditingSlice,
    citatorState: citatorStateSlice,
    subscriptionState: subscriptionReducer,
    onboardingState: onboardingReducer,
    [subscriptionApi.reducerPath]: subscriptionApi.reducer,
    [onboardingApi.reducerPath]: onboardingApi.reducer,
    // Add generated RTK Query reducers (the streaming endpoints are not supported by RTK Query)
    [paxtonAppApi.reducerPath]: paxtonAppApi.reducer, // THIS IS THE NEW RTK QUERY API FOR SUGGESTED QUESTIONS
    [getSourceApi.reducerPath]: getSourceApi.reducer,
    [hostedFiltersApi.reducerPath]: hostedFiltersApi.reducer,
    [chatV2Api.reducerPath]: chatV2Api.reducer,
    [documentEditingApi.reducerPath]: documentEditingApi.reducer,
    [orgAdminApi.reducerPath]: orgAdminApi.reducer,
    [citatorApi.reducerPath]: citatorApi.reducer,
    [caselawReferenceViewerApi.reducerPath]: caselawReferenceViewerApi.reducer,
    [limitApi.reducerPath]: limitApi.reducer,
  },

  // Adding RTK Query API Middleware for caching, invalidation, polling, etc. (the streaming endpoints are not supported by RTK Query)
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    paxtonAppApi.middleware,
    getSourceApi.middleware,
    hostedFiltersApi.middleware,
    chatV2Api.middleware,
    documentEditingApi.middleware,
    orgAdminApi.middleware,
    citatorApi.middleware,
    caselawReferenceViewerApi.middleware,
    subscriptionApi.middleware,
    onboardingApi.middleware,
    limitApi.middleware,
  ],
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
