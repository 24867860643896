import { useAppDispatch } from '@/store/store-hooks'
import { chatV2CurrentSourceResearchAddStateCourtIds, chatV2CurrentSourceResearchAddStateLRRSource } from '@/chat-common/store/chat-v2.slice'
import { FC, memo } from 'react'
import { getStateCourtIds, getStateLRRSources } from './research-source-maps'
import {
  addAllRelatedFederalCourtsAndLRRByState as addAllFederalCourtsAndLRRRelatedToState,
  removeAllFederalCourtsAndLRR,
  removeAllStateCourtsAndLRR,
} from './research-source-utils'

type ResearchDialogStateSelectionProps = {
  conversationId: string
  states: string[]
  selectedState: string | null
  includeFederal: boolean
}

type RenderStatesProps = {
  states: string[]
  selectedState: string | null
  onStateChange: (state: string) => void
}

const RenderStates: FC<RenderStatesProps> = memo((props: RenderStatesProps) => {
  const { states, selectedState, onStateChange } = props
  return (
    <>
      {states.map((state: string) => {
        const stateValue = state.toLowerCase()

        return (
          <div key={state} className="mb-2">
            <div className="flex items-center mb-1">
              <input
                type="radio"
                className="h-4 w-4 rounded-full border-gray-300 text-sky-600 focus:ring-sky-600"
                id={state}
                name="stateSelection"
                checked={selectedState === stateValue}
                value={stateValue}
                onChange={() => {
                  // console.log(`Selected ${state}`)
                  onStateChange(stateValue)
                }}
              />
              <label htmlFor={state} className="pl-2 text-sm">
                {state}
              </label>
            </div>
          </div>
        )
      })}
    </>
  )
})

const ResearchDialogStateSelection: FC<ResearchDialogStateSelectionProps> = (props: ResearchDialogStateSelectionProps) => {
  const { conversationId, states, selectedState, includeFederal } = props
  // Store
  const dispatch = useAppDispatch()

  const handleStateChange = (state: string) => {
    // Remove current state courts and LRR
    removeAllStateCourtsAndLRR(dispatch, conversationId)

    // Add selected state courts
    const selectedStateCourts = getStateCourtIds(state)
    dispatch(chatV2CurrentSourceResearchAddStateCourtIds({ conversationId: conversationId, courtIds: selectedStateCourts }))

    // Add selected state lrr
    const selectedStateLrr = getStateLRRSources(state)
    selectedStateLrr.forEach((source) => {
      dispatch(chatV2CurrentSourceResearchAddStateLRRSource({ conversationId: conversationId, source_name: source }))
    })

    if (includeFederal) {
      // Femove all federal courts and LRR
      removeAllFederalCourtsAndLRR(dispatch, conversationId)

      // Add all federal courts and LRR related to state
      addAllFederalCourtsAndLRRRelatedToState(dispatch, conversationId, state)
    }
  }

  // Render state list
  return (
    <div>
      <div className="columns-2 md:columns-3 py-2">
        <RenderStates states={states} selectedState={selectedState} onStateChange={handleStateChange} />
      </div>
    </div>
  )
}

export default ResearchDialogStateSelection
