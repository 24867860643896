import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ChatV2Feature } from './chat-v2.slice'
import { FeedbackThumb } from '../schemas/feedback-schema'

type ChatV2UxState = {
  messageExpandReferences: string[]
  messageExpandRelatedReferences: string[]
  userQueryBubbleExpanded: Partial<Record<string, boolean>>
  messageFeedbackIndicators: Partial<Record<string, FeedbackThumb>>
  lastViewedConvoIdByFeature: Partial<Record<ChatV2Feature, string | null>>
  selectedFilesContentExpanded: Partial<Record<string, boolean>>
}

const initialState: ChatV2UxState = {
  messageExpandReferences: [],
  messageExpandRelatedReferences: [],
  userQueryBubbleExpanded: {},
  messageFeedbackIndicators: {},
  lastViewedConvoIdByFeature: {},
  selectedFilesContentExpanded: {},
}

/**
 * Chat V2 UX Slice
 * A slice to hold state for purely UX state that should not be lost when components are unmounted
 */
export const chatV2UxSlice = createSlice({
  name: 'chatV2UxState',
  initialState,
  reducers: {
    nullifyData: () => initialState,

    toggleMessageExpandReferences: (state, action: PayloadAction<{ messageId: string }>) => {
      const { messageId } = action.payload

      const index = state.messageExpandReferences.indexOf(messageId)

      if (index === -1) {
        state.messageExpandReferences.push(messageId)
      } else {
        state.messageExpandReferences.splice(index, 1)
      }
    },

    toggleMessageExpandRelatedReferences: (state, action: PayloadAction<{ messageId: string }>) => {
      const { messageId } = action.payload

      const index = state.messageExpandRelatedReferences.indexOf(messageId)

      if (index === -1) {
        state.messageExpandRelatedReferences.push(messageId)
      } else {
        state.messageExpandRelatedReferences.splice(index, 1)
      }
    },

    setUserQueryBubbleExpanded: (state, action: PayloadAction<{ messageId: string; expand: boolean }>) => {
      const { messageId, expand } = action.payload

      state.userQueryBubbleExpanded[messageId] = expand
    },

    setLastViewedConvoIdByFeature: (state, action: PayloadAction<{ feature: ChatV2Feature; conversationId: string | null }>) => {
      const { feature, conversationId } = action.payload

      state.lastViewedConvoIdByFeature[feature] = conversationId
    },

    // Migrate pending conversation to server conversation
    migratePendingViewToServerView: (state, action: PayloadAction<{ feature: ChatV2Feature; pendingConversationId: string; conversationId: string }>) => {
      const { feature, pendingConversationId, conversationId } = action.payload

      // console.log(`Feature: ${feature}, Migrating pending conversation ${pendingConversationId} to server conversation ${conversationId}`)

      // If the pending conversation is the last viewed conversation, update the last viewed conversation
      if (state.lastViewedConvoIdByFeature[feature] === pendingConversationId) {
        state.lastViewedConvoIdByFeature[feature] = conversationId
      }
    },

    // Set message feedback indicator
    setMessageFeedbackIndicator: (state, action: PayloadAction<{ messageId: string; thumb: FeedbackThumb }>) => {
      const { messageId, thumb } = action.payload

      state.messageFeedbackIndicators[messageId] = thumb
    },

    setSelectedFilesContentExpanded: (state, action: PayloadAction<{ conversationId: string; expanded: boolean }>) => {
      const { conversationId, expanded } = action.payload
      state.selectedFilesContentExpanded[conversationId] = expanded
    },

    toggleSelectedFilesContentExpanded: (state, action: PayloadAction<{ conversationId: string }>) => {
      const { conversationId } = action.payload
      const current = state.selectedFilesContentExpanded[conversationId] ?? false
      state.selectedFilesContentExpanded[conversationId] = !current
    },
  },
})

// Actions
export const {
  nullifyData,
  toggleMessageExpandReferences,
  toggleMessageExpandRelatedReferences,
  setUserQueryBubbleExpanded,
  setLastViewedConvoIdByFeature,
  migratePendingViewToServerView,
  setMessageFeedbackIndicator,
  setSelectedFilesContentExpanded,
  toggleSelectedFilesContentExpanded,
} = chatV2UxSlice.actions

export default chatV2UxSlice.reducer
