import { useGetSourceLrrV2Query } from '@/chat-common/api/source-apis'
import InlineTipError from '@/components/inline-tips/InlineTipError'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { kLrrSourceSanitizationAllowedTags } from '@/constants/constants-ui'
import { Parser } from 'html-to-react'
import sanitizeHtml from 'sanitize-html'
import { escapeSpecialCharsFromHtmlSourceForSnippet, scrollToFirstHighlight } from './reference-view-utils'
import { findAll } from 'highlight-words-core'
import { useEffect, useRef, useState, Dispatch, SetStateAction } from 'react'
import { useAppSelector } from '@/store/store-hooks'
import { RootState } from '@/store/store'
import { selectVisibleReference, selectVisibleReferenceRenderTrigger } from '@/chat-common/store/chat-v2.selectors'

type ReferenceViewContentLrrV2Props = {
  conversationId: string
  setReferenceHtmlCallback?: Dispatch<SetStateAction<string>>
}

export default function ReferenceViewContentLrrV2(props: ReferenceViewContentLrrV2Props) {
  const { conversationId, setReferenceHtmlCallback } = props
  const sourceContainerRef = useRef<HTMLDivElement>(null)
  const reference = useAppSelector((state: RootState) => selectVisibleReference(state, { chatId: conversationId }))
  const renderTrigger = useAppSelector((state: RootState) => selectVisibleReferenceRenderTrigger(state, { chatId: conversationId }))

  // Isolate the ID of the reference needed to fetch it
  const s3DirRefId = reference?.metadata.s3_dir

  // Support new LRR v2 data versioning
  const dataVersion = reference?.metadata?.data_version ?? 1

  // Configure the HTML parser
  const htmlParser = Parser()

  // Fetch the reference HTML string with RTK-Query
  const {
    data: sourceHtmlData,
    isLoading: sourceHtmlIsLoading,
    isError: sourceHtmlIsError,
    refetch,
  } = useGetSourceLrrV2Query({ s3Dir: s3DirRefId ?? '', dataVersion: dataVersion })
  const htmlString = sourceHtmlData?.data

  // Local state
  const [processedHtml, setProcessedHtml] = useState('')

  // Format Sanitize / Highlight  the HTML every time the sourceHtmlData changes
  useEffect(() => {
    if (!htmlString || !reference) return

    // Sanitize the HTML
    const sanitizedHtml = sanitizeHtml(htmlString, {
      allowedTags: kLrrSourceSanitizationAllowedTags,
      transformTags: { pre: 'p', code: 'p' },
    })

    // Generate highlight snippets from the text source
    const snippets = reference.text
      .split('\n')
      .map((s) => s.trim())
      .slice(2)
      .join(' ')
      .split('.')
      .filter((sentence) => sentence.length > 45)
      .map((sentence) => escapeSpecialCharsFromHtmlSourceForSnippet(sentence))

    // Find all snippets to highlight
    const chunks = findAll({
      caseSensitive: false,
      searchWords: snippets,
      textToHighlight: sanitizedHtml,
    })

    // Format the HTML to wrap highlighted snippets in a span with highlight styles
    const formattedHTML = chunks
      .map((chunk: { end: any; highlight: any; start: any }) => {
        const { end, highlight, start } = chunk
        const text = sanitizedHtml.slice(start, end)
        if (highlight) {
          return `<span class="highlighted-snippet bg-yellow-300">${text}</span>`
        } else {
          return text
        }
      })
      .join('')

    setProcessedHtml(formattedHTML)
    setReferenceHtmlCallback?.(formattedHTML)
  }, [htmlString, renderTrigger])

  // Scroll to the first highlighted portion whenever the HTML string changes
  useEffect(() => {
    scrollToFirstHighlight(sourceContainerRef, '.highlighted-snippet')
  }, [processedHtml, renderTrigger])

  // If no reference
  if (!reference) return <div>No reference selected</div>

  // Show loading indicator
  if (sourceHtmlIsLoading) return <CircularProgressContinuousSized size={18} thickness={7} />

  // Show error
  if (sourceHtmlIsError || !htmlString)
    return (
      <>
        {InlineTipError('Could not load this reference.')}
        <button
          className={
            'flex-auto items-center rounded-md bg-sky-600 border-[1px] border-sky-600 px-3 py-2 m-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500'
          }
          onClick={refetch}
        >
          Retry
        </button>
      </>
    )

  return (
    <div id="lrr-reference lrr-v2" ref={sourceContainerRef} className={'reference-view-html text-sm overflow-y-scroll py-2'}>
      {htmlParser.parse(processedHtml)}
    </div>
  )
}
