import { chatV2RemoveVisibleReference } from '@/chat-common/store/chat-v2.slice'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { Cross2Icon, ExternalLinkIcon } from '@radix-ui/react-icons'
import referenceMetadataToTitle from '@/chat-common/util/reference-metadata-to-title'
import { useState } from 'react'
import ReferenceViewerConstructor from './reference-viewer-constructor'
import { downloadReferenceAsDocX } from '@/chat-common/util/download-conversation'
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { useAnalytics } from '@/analytics/hooks/useAnalytics'
import { AnalyticsEvent } from '@/analytics/schema/events.schema'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { Link } from 'react-router-dom'
import { isCitatorEnabled } from './reference-view-utils'
import { RootState } from '@/store/store'
import { selectConversationFeature, selectVisibleReference } from '@/chat-common/store/chat-v2.selectors'

type ReferenceViewerColumnProps = {
  conversationId: string
}

export default function ReferenceViewerColumn(props: ReferenceViewerColumnProps) {
  const { conversationId } = props
  const { trackEvent } = useAnalytics()
  const dispatch = useAppDispatch()

  // Feature
  const feature = useAppSelector((state: RootState) => selectConversationFeature(state, { chatId: conversationId }))

  // Local state
  const [referenceHtml, setReferenceHtml] = useState('')
  const [downloadLoading, setDownloadLoading] = useState(false)

  // Active reference
  const visibleReference = useAppSelector((state: RootState) => selectVisibleReference(state, { chatId: conversationId }))
  if (!visibleReference) return null

  // Inferred values
  const rawTitle = visibleReference.metadata?.title
  const parentId = visibleReference.metadata?.parent_id

  // Generate reference title
  const title = referenceMetadataToTitle(visibleReference)

  return (
    <div className="bg-white flex flex-grow flex-col h-full">
      <button
        className={'float-right z-50 fixed top-1 right-2 border p-1 border-gray-50 rounded-full bg-white hover:bg-gray-100'}
        onClick={() => dispatch(chatV2RemoveVisibleReference({ conversationId: conversationId }))}
      >
        <Cross2Icon className={'w-6 h-6'} />
      </button>

      <div className="flex flex-grow flex-col h-full w-full p-2">
        <div className={'flex gap-x-4 items-end text-normal font-bold mb-2 border-b pb-2'}>
          [{visibleReference.reference_number}]
          {parentId && (
            <Link to={`/dashboard/reference-viewer/caselaw/${parentId}`} target="_blank" className={'flex items-end hover:text-sky-700 active:bg-sky-100'}>
              <span className={'text-sm font-medium leading-tight'}>New tab</span> <ExternalLinkIcon className={'w-4 h-4 pl-[4px] pb-[0px]'} />
            </Link>
          )}
          <button
            className={`flex items-end hover:text-sky-700 active:bg-sky-100 ${referenceHtml ? '' : 'hidden'}`}
            onClick={() => {
              trackEvent(AnalyticsEvent.DownloadChatSourceToDocx)
              downloadReferenceAsDocX(referenceHtml, rawTitle ?? 'source', setDownloadLoading)
            }}
          >
            <span className={'text-sm font-medium leading-tight'}>.docx</span>
            <ArrowDownTrayIcon className={'h-4 pl-[4px] pb-[2px]'} />
            {downloadLoading && (
              <span className={'pl-2 pb-[1px] h-5'}>
                <CircularProgressContinuousSized size={12} thickness={7} />
              </span>
            )}
          </button>
        </div>
        {/* Border and spacing compensation for citator button */}
        <div className={`text-sm whitespace-pre-wrap break-all font-bold ${isCitatorEnabled(feature, visibleReference) ? '' : 'pb-2 border-b'}`}>{title}</div>
        <ReferenceViewerConstructor conversationId={conversationId} setReferenceHtmlCallback={setReferenceHtml} />
      </div>
    </div>
  )
}
