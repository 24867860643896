import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { TrashIcon } from '@radix-ui/react-icons'
import * as Dialog from '@radix-ui/react-dialog'
import { useState } from 'react'

type DeleteConfirmDialogProps = {
  title: string
  fileMessage: string | null
  folderMessage: string | null
  // async onConfirm function
  onConfirm: () => Promise<void>
  closeDialog: () => void
  visible: boolean
}

export default function DeleteConfirmDialog(props: DeleteConfirmDialogProps) {
  const { title, fileMessage, folderMessage, onConfirm, closeDialog, visible } = props
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  if (!visible) return null

  return (
    <Dialog.Root open={visible}>
      <Dialog.Portal>
        <Dialog.Overlay className="bg-blackA6 data-[state=open]:animate-overlayShow fixed inset-0" />
        <Dialog.Content className="data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
          <Dialog.Title className="m-0 text-[17px] font-medium">
            <h2 className="mb-2 text-lg font-semibold flex gap-x-2 items-center">
              <TrashIcon className="h-10 w-10 p-2 scale-75 rounded-full text-sky-700 bg-sky-100" />
              {title}
            </h2>
          </Dialog.Title>
          <Dialog.Description className="mt-[10px] mb-5 text-[15px] leading-normal">
            {folderMessage && <p className="mt-2 ml-4">{folderMessage}</p>}
            {fileMessage && <p className="mt-2 ml-4">{fileMessage}</p>}
            {error && <p className={'mt-2 ml-4 text-red-700'}>{error}</p>}
          </Dialog.Description>
          <div className="mt-[25px] gap-3 flex justify-end">
            <Dialog.Close asChild>
              <button
                className={`bg-white text-gray-900 ring-gray-300 hover:bg-gray-50 mt-3 inline-flex w-full items-center justify-center rounded-md gap-2 px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset sm:w-auto h-auto`}
                onClick={closeDialog}
              >
                Cancel
              </button>
            </Dialog.Close>
            <Dialog.Close asChild>
              <button
                className={`bg-sky-600 text-white hover:bg-sky-500 mt-3 inline-flex w-full items-center justify-center rounded-md gap-2 px-3 py-2 text-sm font-semibold shadow-sm sm:w-auto h-auto`}
                onClick={async () => {
                  setLoading(true)
                  setError(null)
                  try {
                    await onConfirm()
                    closeDialog()
                  } catch (error) {
                    console.error(error)
                    setError('There was an error deleting the files.')
                  } finally {
                    setLoading(false)
                  }
                }}
              >
                <div>Delete</div>
                {loading && <CircularProgressContinuousSized size={12} thickness={7} hexColor={'#ffffff'} />}
              </button>
            </Dialog.Close>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
