import React from 'react'
import { ChatV2QueryMetadataWebsearchQuery, initialWebsearchMetadata } from '@/chat-common/schemas/chat-query-metadata-schema'
import { chatV2CurrentSourceWebsearchSetMetadata } from '@/chat-common/store/chat-v2.slice'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { RootState } from '@/store/store'
import { selectConversationCurrentSource } from '@/chat-common/store/chat-v2.selectors'

type ChatControlsWebsearchProps = {
  conversationId: string
}

const ChatControlsWebsearch: React.FC<ChatControlsWebsearchProps> = (props) => {
  const { conversationId } = props
  const dispatch = useAppDispatch()

  // Source information
  let currentSource = useAppSelector((state: RootState) =>
    selectConversationCurrentSource(state, { chatId: conversationId })
  ) as ChatV2QueryMetadataWebsearchQuery | null

  // If null
  if (currentSource === null) {
    currentSource = initialWebsearchMetadata
  }
  const showWebsiteInput = currentSource.site ? true : false

  return (
    <div className={'mt-1'}>
      <div className="mb-0 pb-0">
        <div className="rounded-lg bg-yellow-50 p-4 my-4 grid grid-cols-[45px_auto] items-center">
          <div className={'flex h-7 w-7 text-yellow-700 bg-yellow-50 rounded-lg p-1 ring-4 ring-white items-center justify-center'}>
            <InformationCircleIcon />
          </div>
          <span className={'text-xs text-yellow-900 text-opacity-70'}>This tool can search either the entire web or a specific site or domain.</span>
        </div>
      </div>
      <div className={'flex flex-wrap gap-2'}>
        <div>
          <p className="text-sm px-0 pb-1 font-semibold ">Search type:</p>
          <div className={'flex flex-row gap-x-2 items-center pb-2'}>
            <div className="mx-2">
              <input
                type="radio"
                id="web"
                name="searchType"
                value="web"
                checked={currentSource.type === 'web' && !showWebsiteInput}
                onChange={() => {
                  dispatch(
                    chatV2CurrentSourceWebsearchSetMetadata({
                      conversationId: conversationId,
                      websearchMetadata: { type: 'web', site: null },
                    })
                  )
                }}
                className="form-radio text-blue-500 h-4 w-4"
              />
              <label htmlFor="web" className="ml-2 text-gray-700">
                Web
              </label>
            </div>
            <div className="mx-2">
              <input
                type="radio"
                id="news"
                name="searchType"
                value="news"
                checked={currentSource.type === 'news'}
                onChange={() => {
                  dispatch(
                    chatV2CurrentSourceWebsearchSetMetadata({
                      conversationId: conversationId,
                      websearchMetadata: { type: 'news', site: null },
                    })
                  )
                }}
                className="form-radio text-blue-500 h-4 w-4"
              />
              <label htmlFor="news" className="ml-2 text-gray-700">
                News
              </label>
            </div>
            <div className="mx-2">
              <input
                type="radio"
                id="specific_website"
                name="searchType"
                value="web"
                checked={showWebsiteInput}
                onChange={() => {
                  dispatch(
                    // Set '' to support placeholder test and show input
                    chatV2CurrentSourceWebsearchSetMetadata({
                      conversationId: conversationId,
                      websearchMetadata: { type: 'web', site: ' ' },
                    })
                  )
                }}
                className="form-radio text-blue-500 h-4 w-4"
              />
              <label htmlFor="specific_website" className="ml-2 text-gray-700">
                Specific Site
              </label>
            </div>
          </div>
        </div>

        {showWebsiteInput && (
          <div>
            <p className="text-sm px-0 font-semibold transition ease-in-out">Site or Domain (Optional):</p>
            <input
              id="site"
              className="block min-w-72 w-full rounded-md border-0 text-gray-900 text-sm shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-3 focus:ring-2 focus:ring-inset focus:ring-sky-600"
              type="text"
              placeholder="Search a site or limit to a domain"
              value={currentSource.site ?? ''}
              onChange={(event) => {
                dispatch(
                  chatV2CurrentSourceWebsearchSetMetadata({
                    conversationId: conversationId,
                    websearchMetadata: { type: currentSource?.type ?? initialWebsearchMetadata.type, site: event.target.value },
                  })
                )
              }}
              autoFocus
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default ChatControlsWebsearch
