import { useParams } from 'react-router'
import {
  ChatV2Feature,
  chatV2CurrentSourceContractAnalysisSetAsOnlySelectedFile,
  chatV2CurrentSourceDocumentQueryToggleSelectedFile,
  chatV2CurrentSourceDocumentSummarizationToggleSelectedFile,
} from '../store/chat-v2.slice'
import FilesDropZone, { UploadTaskStatus } from '@/routes/dashboard/files/FilesDropZone'
import { useEffect, useState, Dispatch, SetStateAction } from 'react'
import { CircularProgressDeterminate } from '@/components/loaders/CircularProgressDeterminate'
import { StorageReference, getStorage, ref, FullMetadata } from 'firebase/storage'
import { kGcsUserFileUploadsBucketRef } from '@/constants/constants-gcs'
import { getAuth } from 'firebase/auth'
import { getStorageFolderNameByFeature } from './chat-window-dropzone-utils'
import { useAppDispatch } from '@/store/store-hooks'
import { fileUploadIsProcessing, getFileMetadataFromGCS } from '@/routes/dashboard/files/files-utils'
import { CircularProgressContinuous } from '@/components/loaders/CircularProgressContinuous'

const supportedChatFeatures = [ChatV2Feature.documentSummarization, ChatV2Feature.documentquery]

// Use overrideFeature & setProcessedStateCallback to support non-chat features
type ChatWindowDropZoneProps = {
  onUpdate: () => void
  overrideFeature?: ChatV2Feature
  setProcessedStateCallback?: Dispatch<SetStateAction<FullMetadata | null>>
}

export default function ChatWindowDropZone(props: ChatWindowDropZoneProps) {
  const { onUpdate, overrideFeature, setProcessedStateCallback } = props
  const { chatFeature, chatId } = useParams()

  const feature = overrideFeature || chatFeature // combine the feature from the URL and the override feature

  const dispatch = useAppDispatch()

  // Local state
  const [uploadTask, setUploadTask] = useState<UploadTaskStatus | null>(null)
  const [fileProcessing, setFileProcessing] = useState<boolean>(false)

  // Get the current user
  const auth = getAuth()
  const uid = auth.currentUser?.uid

  // Derive the currentFolder for uploads
  const storage = getStorage(undefined, kGcsUserFileUploadsBucketRef)

  const uploadFolderPath = `users/${uid}/${getStorageFolderNameByFeature(feature as ChatV2Feature)}`
  const currentFolderRef: StorageReference | null = ref(storage, uploadFolderPath)

  // Check the status of the fileProcessing for completed file
  useEffect(() => {
    if (!feature) return
    if (!uploadTask) return
    if (!uploadTask.complete) return

    const fileFullPath = `${uploadFolderPath}/${uploadTask.uploadableFile.fileName}`

    // Set the selected files in the conversation
    async function pollFileProcessingStatus(file: string) {
      onUpdate()

      // Check the status of this file's processing in GCS
      const fileMetadata = await getFileMetadataFromGCS(file)
      const isProcessing = fileUploadIsProcessing(fileMetadata)
      // console.log('fileMetadata', fileMetadata)
      // console.log('isProcessing', isProcessing)

      // Set the fileProcessing state
      if (isProcessing && !fileProcessing) {
        setFileProcessing(isProcessing)
      }

      // If the file is still processing, poll again in 2 seconds
      if (isProcessing) {
        setTimeout(() => pollFileProcessingStatus(file), 2000)
      }

      // If the file is no longer processing, set the fileProcessing state to false
      if (!isProcessing && fileProcessing) {
        if (chatId) {
          // Set it as the chat's currentSource selected file
          switch (feature as ChatV2Feature) {
            case ChatV2Feature.contractanalysis:
              dispatch(
                chatV2CurrentSourceContractAnalysisSetAsOnlySelectedFile({
                  conversationId: chatId,
                  filePath: fileFullPath,
                })
              )
              break
            case ChatV2Feature.documentquery:
              dispatch(chatV2CurrentSourceDocumentQueryToggleSelectedFile({ conversationId: chatId, filePath: fileFullPath }))
              break
            case ChatV2Feature.documentSummarization:
              dispatch(chatV2CurrentSourceDocumentSummarizationToggleSelectedFile({ conversationId: chatId, selectedFile: fileFullPath }))
              break
            default:
              console.error(`No action defined for chat feature ${feature}.`)
              break
          }
        } else if (setProcessedStateCallback) {
          // Set support for non-chat features
          switch (feature as ChatV2Feature) {
            case ChatV2Feature.contractanalysis:
              setProcessedStateCallback(fileMetadata)
              break
            default:
              console.error(`No action defined for feature ${feature}.`)
              break
          }
        }

        setFileProcessing(false)
        setUploadTask(null)
      }
    }

    pollFileProcessingStatus(fileFullPath)
  }, [feature, chatId, dispatch, fileProcessing, uploadFolderPath, uploadTask])

  // Files Drop Zone - feature specific
  const isFileDropZoneFeature = overrideFeature || (chatId && supportedChatFeatures.includes(chatFeature as ChatV2Feature))

  if (!isFileDropZoneFeature) {
    return null
  }

  return (
    <div className="px-4">
      <FilesDropZone
        onTaskListItemUpdated={function (uploadTask: UploadTaskStatus): void {
          onUpdate()
          setUploadTask(uploadTask)

          // When the upload is completed, set it as the chat's currentSource selected file
          if (uploadTask.complete) {
            console.log('File upload completed: ', uploadTask.uploadableFile.fileName)
          }
        }}
        currentFolder={currentFolderRef}
        singleFileLimit={true}
        hideUploadProgressOverlay={true}
      />

      {uploadTask && (
        <div className={'my-2 grid grid-cols-[auto_30px] items-center bg-white rounded-xl p-2'}>
          <div className={'inline-block align-middle text-sm overflow-hidden'}>
            <p>{uploadTask.uploadableFile.fileName}</p>
            <p className={'text-red-600 text-xs'}>{uploadTask.errorMessage.length == 0 ? '' : uploadTask.errorMessage}</p>
          </div>
          <div className={'inline-block align-middle'}>
            <CircularProgressDeterminate value={uploadTask.progress} error={uploadTask.errorMessage.length > 0 ? true : false} />
          </div>
        </div>
      )}
      {uploadTask && fileProcessing && (
        <div className={'my-2 grid grid-cols-[auto_30px] items-center bg-white rounded-xl p-2'}>
          <div className={'inline-block align-middle text-sm overflow-hidden'}>
            <p>Processing file...</p>
            <p className={'text-red-600 text-xs'}>{uploadTask.errorMessage.length == 0 ? '' : uploadTask.errorMessage}</p>
          </div>
          <div className={'inline-block align-middle'}>
            <CircularProgressContinuous />
          </div>
        </div>
      )}
    </div>
  )
}
